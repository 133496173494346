import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQc } from "../features/qcSlice";

function QcList({ onChangeQc, qcData }) {
  const [qc, setQc] = useState([]);
  const dispatch = useDispatch();
  const { qcs } = useSelector((state) => state.qc);

  useEffect(() => {
    if (!qcs) {
      dispatch(getQc());
    } else {
      setQc(qcs.result);
    }
  }, [dispatch, qcs]);

  return (
    <select
      className="block appearance-none w-full border border-green-400 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      id="grid-qc"
      value={qcData}
      onChange={onChangeQc}
    >
      {Array.isArray(qc) ? (
        qc.map((order, index) => (
          <option value={order.qc_id} key={`opr-${index}`}>
            {order.qc_name}
          </option>
        ))
      ) : (
        <option value="" key="empty-option">
          Loading... {/* or any other placeholder */}
        </option>
      )}
    </select>
  );
}

export default QcList;
