import React, { useState, useEffect } from "react";
import { Button, Modal } from "flowbite-react";
import DateTimePicker from "./DateTimePicker";
import { format } from "date-fns";
import axios from "axios";
import StockInOutModal from "./StockInOutModal";
import MaterialListSelect from "./MaterialListSelect";
import MaterialLocationSelect from "./MaterialLocationSelect";

function TransactionRecord() {
  const [selectedMatList, setSelectedMatList] = useState(null);
  const [viewType, setViewType] = useState(0);
  const [searchByType, setSearchByType] = useState("0");
  const [fromDateSelected, setFromDateSelected] = useState(new Date());
  const [toDateSelected, setToDateSelected] = useState(
    new Date(new Date().setDate(new Date().getDate() + 7))
  );
  const [selectedMatGroup, setSelectedMatGroup] = useState(null);
  const [remark, setRemark] = useState("");
  const [record, setRecord] = useState([]);
  const [originalRecord, setOriginalRecord] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [productLocation, setProductLocation] = useState("");

  const handleProductLocationChange = (locationId) => {
    setProductLocation(locationId);
  };

  const handleMatListChange = (Arr) => {
    setSelectedMatList(Arr[0]);
  };

  const handleRemarkInputChange = (e) => {
    const uppercaseValue = e.target.value.toUpperCase();
    setRemark(uppercaseValue);
  };

  useEffect(() => {
    filterTransaction();
  }, [selectedMatGroup]);

  const getAllTransaction = async () => {
    const response = await axios.get(
      `https://eps.elkomprintama.com/get/api/store/transaction/?from=${format(
        fromDateSelected,
        "yyyy-MM-dd"
      )}&to=${format(toDateSelected, "yyyy-MM-dd")}`
    );
    setRecord(response.data.result);
    setOriginalRecord(response.data.result);
  };

  const searchTransactionById = async (product_id) => {
    const response = await axios.get(
      `https://eps.elkomprintama.com/get/api/store/transaction/${product_id}`
    );
    setRecord(response.data.result);
    setOriginalRecord(response.data.result);
  };

  const searchTransactionByRemark = async (remark) => {
    const response = await axios.get(
      `https://eps.elkomprintama.com/get/api/store/transaction/search?search=${remark}`
    );
    setRecord(response.data.result);
    setOriginalRecord(response.data.result);
  };

  const getStockInTransaction = async (transaction_type) => {
    const encodedValue = encodeURIComponent(transaction_type);
    console.log(encodedValue);
    try {
      const response = await axios.get(
        `https://eps.elkomprintama.com/get/api/store/transaction/type?from=${format(
          fromDateSelected,
          "yyyy-MM-dd"
        )}&to=${format(toDateSelected, "yyyy-MM-dd")}&types=${encodedValue}`
      );
      setRecord(response.data.result);
      setOriginalRecord(response.data.result);
    } catch (error) {
      // Log the error or handle it as needed
      console.error("Error fetching data:", error);
      // Perform actions to handle the error (e.g., show a message to the user)
    }
  };

  const handleToDateChange = (date) => {
    if (date < fromDateSelected) {
      alert("Cannot smaller than 'from date'");
      return;
    }
    setToDateSelected(date);
  };

  const handleFromDateChange = (date) => {
    setFromDateSelected(date);
  };

  const handleViewTypeChange = (e) => {
    setViewType(e.target.value);
    setRecord([]);
    setOriginalRecord([]);
    setRemark("");
  };

  const handleSearchTypeChange = (e) => {
    setSearchByType(e.target.value);
    setRecord([]);
    setRemark("");
  };

  function applyStripedStyle(index, data) {
    const isVoid =
      data.transaction_remark && data.transaction_remark.includes("[VOID]");
    return isVoid
      ? "bg-red-300"
      : index % 2 === 0
      ? "border-b bg-gray-50"
      : "bg-white";
  }

  const filterTransaction = () => {
    if (!selectedMatGroup) {
      setRecord(originalRecord);
    } else {
      const groupId = parseInt(selectedMatGroup, 10); // Convert to number if needed
      const filteredRecords = originalRecord.filter(
        (item) => item.group_id === groupId
      );
      setRecord(filteredRecords);
    }
  };
  const checkAllTransaction = (e) => {
    e.preventDefault();
    switch (viewType) {
      case "0":
        getAllTransaction();

        break;
      case "1":
        getStockInTransaction("Stock In");
        break;
      case "2":
        getStockInTransaction("Stock Out");
        break;
      case "3":
        if (searchByType === "0") {
          searchTransactionById(selectedMatList);
          break;
        } else {
          if (!remark) {
            alert("Remark tidak boleh kosong!");
            break;
          }
          searchTransactionByRemark(remark);
          break;
        }

      default:
        getAllTransaction();
    }
  };

  return (
    <>
      <div className="flex flex-col h-full bg-gray-200 px-5 py-5 sm:rounded-lg">
        <div className="control w-full  ">
          <div className="w-full">
            <label
              className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
              htmlFor="show-option"
            >
              Show Transaction Record
            </label>

            <select
              className={`block appearance-none w-full border  border-green-400"
              text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="show-option"
              value={viewType}
              onChange={handleViewTypeChange}
            >
              <option value="0" key="Viewoption0">
                SHOW ALL
              </option>
              <option value="1" key="Viewoption1">
                STOCK IN
              </option>
              <option value="2" key="Viewoption2">
                STOCK OUT
              </option>
              <option value="3" key="Viewoption3">
                SEARCH
              </option>
            </select>
          </div>
        </div>
        <div>
          <div className="field w-full my-2">
            {viewType === "3" ? (
              <div className="flex flex-wrap -mx-3 mb-5">
                <div className="w-full px-3 ">
                  <label
                    className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                    htmlFor="stock_in_remark"
                  >
                    By
                  </label>
                  <select
                    className={`block appearance-none w-full border  border-green-400"
              text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                    id="show-option"
                    value={searchByType}
                    onChange={handleSearchTypeChange}
                  >
                    <option value="0" key="SearchOption0">
                      PRODUCT NAME OR CODE
                    </option>
                    <option value="1" key="SearchOption1">
                      REMARK
                    </option>
                  </select>

                  {searchByType === "0" ? (
                    <div className="mt-2">
                      <MaterialLocationSelect
                        onChangeList={handleProductLocationChange}
                      ></MaterialLocationSelect>
                      <MaterialListSelect
                        onChangeList={handleMatListChange}
                        location_id={productLocation}
                      ></MaterialListSelect>
                    </div>
                  ) : (
                    <div className="mt-2">
                      <label
                        className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                        htmlFor="stock_in_remark"
                      >
                        REMARK / OPERATOR
                      </label>
                      <input
                        className="appearance-none uppercase block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="stock_in_remark"
                        type="text"
                        placeholder="REMARK / OPERATOR"
                        value={remark}
                        onChange={handleRemarkInputChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="control w-full  ">
                <DateTimePicker
                  firstLabel={"From"}
                  secondLabel={"To"}
                  firstDateValue={fromDateSelected}
                  secondDateValue={toDateSelected}
                  onChangeFirstDate={handleFromDateChange}
                  onChangeSecondDate={handleToDateChange}
                ></DateTimePicker>
              </div>
            )}

            <form onSubmit={checkAllTransaction}>
              <Button className="mt-5" fullSized type="submit">
                Check Records
              </Button>
            </form>
          </div>
        </div>
        <div className="overflow-auto shadow-md sm:rounded-lg h-[62vh]">
          <table className="text-sm text-left table-auto text-gray-500 dark:text-gray-400 w-full border-collapse">
            <thead className="sticky top-0 text-xs text-white uppercase bg-gray-600 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 py-5 flex-1 border-r"
                >
                  No
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Product Name
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Code
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Group
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Stock In
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Stock Out
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Customer
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Remark
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Input By
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Input Time
                </th>
                <th scope="col" className="whitespace-nowrap px-5 flex-1">
                  Last Modified
                </th>
              </tr>
            </thead>
            <tbody>
              {record.length > 0 ? (
                record.map((data, index) => (
                  <tr
                    onDoubleClick={() => {
                      setShowModal(!showModal);
                      setModalData(data);
                    }}
                    className={`border-b  dark:bg-gray-900 dark:border-gray-700 hover:bg-blue-100 dark:hover:bg-gray-600 ${applyStripedStyle(
                      index,
                      data
                    )} `}
                    key={"TS " + index}
                  >
                    <td className="whitespace-nowrap px-5 py-5 border-r">
                      {index + 1}
                    </td>
                    <td className="whitespace-nowrap px-5 border-r">
                      {data.product_name}
                    </td>
                    <td className="whitespace-nowrap px-5 border-r">
                      {data.product_code}
                    </td>
                    <td className="whitespace-nowrap px-5 border-r">
                      {data.group_name}
                    </td>
                    <td className="whitespace-nowrap px-5 border-r">
                      {data.stock_in}
                    </td>
                    <td className="whitespace-nowrap px-5 border-r">
                      {data.stock_out}
                    </td>
                    <td
                      className={`whitespace-nowrap px-5  ${
                        data.transaction_type === "Stock In"
                          ? "bg-green-200"
                          : "bg-red-200"
                      } border-r`}
                    >
                      {data.transaction_type}
                    </td>
                    <td className="whitespace-nowrap px-5 border-r">
                      {data.transaction_for}
                    </td>
                    <td className={`whitespace-nowrap px-5 border-r`}>
                      {data.transaction_remark}
                    </td>
                    <td className="whitespace-nowrap px-5 border-r">
                      {data.transaction_by}
                    </td>
                    <td className="whitespace-nowrap px-5 border-r">
                      {format(
                        new Date(data.transaction_time),
                        "dd-MM-yyyy HH:mm:ss"
                      )}
                    </td>
                    <td className="whitespace-nowrap px-5">
                      {format(
                        new Date(data.transaction_modified),
                        "dd-MM-yyyy HH:mm:ss"
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className="whitespace-nowrap px-5 py-5">
                    <p className="text-gray-900">No data available</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {showModal ? (
        <div
          className={`fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex flex-col justify-center items-center`}
          // id="wrapper"
          // onClick={handleClose}
        >
          <div className="w-full max-w-2xl text-end ">
            <button
              className="text-red-700 pr-1 pb-1 font-bold text-xl"
              onClick={() => {
                setShowModal(!showModal);
              }}
            >
              X
            </button>
          </div>
          <StockInOutModal
            dataSet={modalData}
            onClose={() => {
              setShowModal(false);
            }}
            refreshData={() => {
              switch (viewType) {
                case "0":
                  getAllTransaction();

                  break;
                case "1":
                  getStockInTransaction("Stock In");
                  break;
                case "2":
                  getStockInTransaction("Stock Out");
                  break;
                case "3":
                  if (!remark) {
                    alert("Remark tidak boleh kosong!");
                    break;
                  }
                  searchTransactionByRemark(remark);
                  break;

                default:
                  getAllTransaction();
              }
            }}
          ></StockInOutModal>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

{
  /* <tr>
  <td colSpan="4">
    <p>No data available</p>
  </td>
</tr>; */
}

export default TransactionRecord;
