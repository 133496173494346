import React, { useState } from "react";
import {
  BsFillArrowLeftCircleFill,
  BsChevronDown,
  BsBoxSeam,
  BsFileEarmarkPdf,
  BsFillPeopleFill,
  BsBoundingBoxCircles,
} from "react-icons/bs";
import { TfiDashboard, TfiReceipt, TfiBag, TfiPanel } from "react-icons/tfi";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleJobsheet,
  toggleOrders,
  toggleQuote,
  toggleStore,
  toggleDocs,
  toggleUsers,
  toggleDiecut,
} from "../features/PageSlice";

import logo from "../logo.png";
import { NavLink } from "react-router-dom";
import LoginStatus from "../components/LoginStatus";

const Layout = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const { subQuote, subJobsheet, subOrders, subStore, subDocs, subDiecut } =
    useSelector((state) => state.pages);

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <div className="bg-gray-900 text-white p-4 flex justify-between items-center">
          <div className="flex items-center gap-4">
            {/* Logo and site title */}
            <img
              src={logo}
              width="80"
              height="80"
              alt="logo"
              className="cursor-pointer block duration-500"
            />
            <p className="text-white font-medium whitespace-nowrap">
              Elkom Printama Batam
            </p>
          </div>
          {/* Login status */}
          <LoginStatus />
        </div>

        {/*Sidebar */}
        <div className="sm:flex max_sm:text-cyan-700 w-full flex-1">
          <div
            className={`bg-gray-800  px-5  ${
              open ? "sm:w-1/6 max_sm:w-full" : "w-20"
            } duration-100 relative`}
          >
            <BsFillArrowLeftCircleFill
              className={`bg-white text-3xl rounded-full absolute z-50 -right-3 top-9 border  cursor-pointer ${
                !open && "rotate-180"
              }`}
              onClick={() => {
                setOpen(!open);
              }}
            />

            <ul className="pt-2 max_sm:text-center">
              {user && user.user_lvl !== 5 && (
                <>
                  <li className="text-gray-300 text-sm flex  items-center gap-x-4 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2">
                    <NavLink
                      className={
                        "text-base items-center font-medium flex flex-row"
                      }
                      to={"/dashboard"}
                    >
                      <TfiDashboard size={"2em"} />
                      <span
                        className={`pl-5   ${!open && "hidden"} duration-200`}
                      >
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  {user.user_lvl !== 2 && user.user_lvl !== 3 && (
                    <li
                      onClick={() => dispatch(toggleQuote())}
                      className="text-gray-300 text-sm flex flex-row items-center gap-x-4 cursor-pointer p-2 select-none hover:bg-light-white rounded-md mt-2"
                    >
                      <div
                        className={
                          "text-base items-center font-medium flex flex-1 flex-row"
                        }
                      >
                        <TfiReceipt size={"2em"} />
                        <span
                          className={`pl-5  ${!open && "hidden"} duration-200`}
                        >
                          Quotation
                        </span>
                      </div>
                      <BsChevronDown
                        className={` ${!open && "hidden"}`}
                        onClick={() => dispatch(toggleQuote())}
                      />
                    </li>
                  )}

                  <ul className={`${subQuote ? "" : "hidden"}`}>
                    <li
                      className={`text-gray-300 text-sm flex items-center gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                        !open && "hidden"
                      } `}
                    >
                      <NavLink
                        className={" items-center flex flex-1 flex-row"}
                        to={"/quotation/add"}
                      >
                        Add Quotation
                      </NavLink>
                    </li>
                    <li
                      className={`text-gray-300 text-sm flex items-center gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                        !open && "hidden"
                      } `}
                    >
                      <NavLink
                        className={" items-center flex flex-1 flex-row"}
                        to={"/quotation"}
                      >
                        Search Quotation
                      </NavLink>
                    </li>
                  </ul>

                  <li
                    onClick={() => dispatch(toggleJobsheet())}
                    className="text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 select-none hover:bg-light-white rounded-md mt-2"
                  >
                    <div
                      className={
                        "text-base items-center font-medium flex flex-1 flex-row"
                      }
                    >
                      <TfiPanel size={"2em"} />
                      <span
                        className={`pl-5  ${!open && "hidden"} duration-200`}
                      >
                        Jobsheet
                      </span>
                    </div>
                    <BsChevronDown
                      className={`${!open && "hidden"}`}
                      onClick={() => dispatch(toggleJobsheet())}
                    />
                  </li>
                  <ul className={`${subJobsheet ? "" : "hidden"}`}>
                    <li
                      className={`text-gray-300 text-sm flex items-center gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                        !open && "hidden"
                      } `}
                    >
                      <NavLink
                        className={
                          " items-center flex flex-1 flex-row select-none"
                        }
                        to={"/jobsheet"}
                      >
                        Search Jobsheet
                      </NavLink>
                    </li>
                  </ul>
                  <li
                    onClick={() => {
                      dispatch(toggleOrders());
                    }}
                    className="text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 select-none hover:bg-light-white rounded-md mt-2"
                  >
                    <div
                      className={
                        "text-base items-center font-medium flex flex-1 flex-row select-none"
                      }
                    >
                      <TfiBag size={"2em"} />
                      <span
                        className={`pl-5  ${!open && "hidden"} duration-200`}
                      >
                        Orders
                      </span>
                    </div>
                    <BsChevronDown
                      className={`${!open && "hidden"}`}
                      onClick={() => dispatch(toggleOrders())}
                    />
                  </li>
                  <ul className={`${subOrders ? "" : "hidden"}`}>
                    <li
                      className={`text-gray-300 text-sm flex items-center select-none gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                        !open && "hidden"
                      } `}
                    >
                      <NavLink
                        className={" items-center flex flex-1 flex-row"}
                        to={"/orderlist"}
                      >
                        Show Orders
                      </NavLink>
                    </li>
                  </ul>
                  <ul className={`${subOrders ? "" : "hidden"}`}>
                    <li
                      className={`text-gray-300 text-sm flex items-center select-none gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                        !open && "hidden"
                      } `}
                    >
                      <NavLink
                        className={" items-center flex flex-1 flex-row"}
                        to={"/addOrder"}
                      >
                        New Order
                      </NavLink>
                    </li>
                  </ul>

                  <li
                    onClick={() => dispatch(toggleDocs())}
                    className="text-gray-300 text-sm flex flex-row items-center gap-x-4 cursor-pointer p-2 select-none hover:bg-light-white rounded-md mt-2"
                  >
                    <div
                      className={
                        "text-base items-center font-medium flex flex-1 flex-row"
                      }
                      to={"/documents"}
                    >
                      <BsFileEarmarkPdf size={"2em"} />
                      <span
                        className={`pl-5  ${!open && "hidden"} duration-200`}
                      >
                        Documents
                      </span>
                    </div>
                    <BsChevronDown
                      className={` ${!open && "hidden"}`}
                      onClick={() => dispatch(toggleDocs())}
                    />
                  </li>
                  <ul className={`${subDocs ? "" : "hidden"}`}>
                    <li
                      className={`text-gray-300 text-sm flex items-center select-none gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                        !open && "hidden"
                      } `}
                    >
                      <NavLink
                        className={" items-center flex flex-1 flex-row"}
                        to={"/msds"}
                      >
                        MSDS
                      </NavLink>
                    </li>
                  </ul>
                  <ul className={`${subDocs ? "" : "hidden"}`}>
                    <li
                      className={`text-gray-300 text-sm flex items-center select-none gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                        !open && "hidden"
                      } `}
                    >
                      <NavLink
                        className={" items-center flex flex-1 flex-row"}
                        to={"/icp"}
                      >
                        ICP
                      </NavLink>
                    </li>
                  </ul>
                  <ul className={`${subDocs ? "" : "hidden"}`}>
                    <li
                      className={`text-gray-300 text-sm flex items-center select-none gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                        !open && "hidden"
                      } `}
                    >
                      <NavLink
                        className={" items-center flex flex-1 flex-row"}
                        to={"/specs"}
                      >
                        Specs
                      </NavLink>
                    </li>
                  </ul>
                </>
              )}
              <li
                onClick={() => dispatch(toggleDiecut())}
                className="text-gray-300 text-sm flex flex-row items-center gap-x-4 cursor-pointer p-2 select-none hover:bg-light-white rounded-md mt-2"
              >
                <div
                  className={
                    "text-base items-center font-medium flex flex-1 flex-row"
                  }
                  to={"/documents"}
                >
                  <BsBoundingBoxCircles size={"2em"} />
                  <span className={`pl-5  ${!open && "hidden"} duration-200`}>
                    Diecut
                  </span>
                </div>
                <BsChevronDown
                  className={` ${!open && "hidden"}`}
                  onClick={() => dispatch(toggleDiecut())}
                />
              </li>

              <ul className={`${subDiecut ? "" : "hidden"}`}>
                <li
                  className={`text-gray-300 text-sm flex items-center select-none gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                    !open && "hidden"
                  } `}
                >
                  <NavLink
                    className={" items-center flex flex-1 flex-row"}
                    to={"/add_diecut"}
                  >
                    Add Diecut
                  </NavLink>
                </li>
              </ul>
              <ul className={`${subDiecut ? "" : "hidden"}`}>
                <li
                  className={`text-gray-300 text-sm flex items-center select-none gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                    !open && "hidden"
                  } `}
                >
                  <NavLink
                    className={" items-center flex flex-1 flex-row"}
                    to={"/diecut-list"}
                  >
                    Diecut List
                  </NavLink>
                </li>
              </ul>
              <li
                onClick={() => dispatch(toggleStore())}
                className="text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 select-none hover:bg-light-white rounded-md mt-2"
              >
                <div
                  className={
                    "text-base items-center font-medium flex flex-1 flex-row"
                  }
                  to={"/currentstock"}
                >
                  <BsBoxSeam size={"2em"} />
                  <span className={`pl-5  ${!open && "hidden"} duration-200 `}>
                    Store
                  </span>
                </div>
                <BsChevronDown
                  className={`${!open && "hidden"}`}
                  onClick={() => dispatch(toggleStore())}
                />
              </li>
              <ul className={`${subStore ? "" : "hidden"}`}>
                <li
                  className={`text-gray-300 text-sm flex items-center select-none gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                    !open && "hidden"
                  } `}
                >
                  <NavLink
                    className={" items-center flex flex-1 flex-row"}
                    to={"/currentstock"}
                  >
                    Show Current Stocks
                  </NavLink>
                </li>
              </ul>
              <ul className={`${subStore ? "" : "hidden"}`}>
                <li
                  className={`text-gray-300 text-sm flex items-center select-none gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                    !open && "hidden"
                  } `}
                >
                  <NavLink
                    className={" items-center flex flex-1 flex-row"}
                    to={"/addstoregroup"}
                  >
                    New Material Group
                  </NavLink>
                </li>
              </ul>
              <ul className={`${subStore ? "" : "hidden"}`}>
                <li
                  className={`text-gray-300 text-sm flex items-center select-none gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                    !open && "hidden"
                  } `}
                >
                  <NavLink
                    className={" items-center flex flex-1 flex-row"}
                    to={"/addlocation"}
                  >
                    New Material Location
                  </NavLink>
                </li>
              </ul>
              <ul className={`${subStore ? "" : "hidden"}`}>
                <li
                  className={`text-gray-300 text-sm flex items-center select-none gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                    !open && "hidden"
                  } `}
                >
                  <NavLink
                    className={" items-center flex flex-1 flex-row"}
                    to={"/addmaterial"}
                  >
                    New Material
                  </NavLink>
                </li>
              </ul>
              <ul className={`${subStore ? "" : "hidden"}`}>
                <li
                  className={`text-gray-300 text-sm flex items-center select-none gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                    !open && "hidden"
                  } `}
                >
                  <NavLink
                    className={" items-center flex flex-1 flex-row"}
                    to={"/materials"}
                  >
                    Material List
                  </NavLink>
                </li>
              </ul>
              <ul className={`${subStore ? "" : "hidden"}`}>
                <li
                  className={`text-gray-300 text-sm flex items-center select-none gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                    !open && "hidden"
                  } `}
                >
                  <NavLink
                    className={" items-center flex flex-1 flex-row"}
                    to={"/stockin"}
                  >
                    Stock In
                  </NavLink>
                </li>
              </ul>
              <ul className={`${subStore ? "" : "hidden"}`}>
                <li
                  className={`text-gray-300 text-sm flex items-center select-none gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                    !open && "hidden"
                  } `}
                >
                  <NavLink
                    className={" items-center flex flex-1 flex-row"}
                    to={"/stockout"}
                  >
                    Stock Out
                  </NavLink>
                </li>
              </ul>
              <ul className={`${subStore ? "" : "hidden"}`}>
                <li
                  className={`text-gray-300 text-sm flex items-center select-none gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                    !open && "hidden"
                  } `}
                >
                  <NavLink
                    className={" items-center flex flex-1 flex-row"}
                    to={"/transactions"}
                  >
                    Transaction Record
                  </NavLink>
                </li>
              </ul>
              <ul className={`${subStore ? "" : "hidden"}`}>
                <li
                  className={`text-gray-300 text-sm flex items-center select-none gap-x-4  px-5 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                    !open && "hidden"
                  } `}
                >
                  <NavLink
                    className={" items-center flex flex-1 flex-row"}
                    to={"/reports"}
                  >
                    Monthly Report
                  </NavLink>
                </li>
              </ul>

              {user && user.user_lvl === 1 && (
                <li className="text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 select-none hover:bg-light-white rounded-md mt-2">
                  <NavLink
                    className={
                      "text-base items-center font-medium flex flex-1 flex-row"
                    }
                    to={"/users"}
                  >
                    <BsFillPeopleFill size={"2em"} />
                    <span
                      className={`pl-5  ${!open && "hidden"} duration-200 `}
                    >
                      Users
                    </span>
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
          {
            <div className={`px-5 pt-2 bg-gray-700 overflow-x-auto w-full `}>
              {children}
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default Layout;
