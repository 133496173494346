import React, { useState, useEffect } from "react";
import MaterialListSelect from "./MaterialListSelect";
import MaterialGroupSelect from "./MaterialGroupSelect";
import MaterialListModal from "./MaterialListModal";
import { Button } from "flowbite-react";
import MaterialLocationSelect from "./MaterialLocationSelect";
import axios from "axios";

function MaterialList() {
  const [matList, setMatList] = useState([]);
  const [selectedMatList, setSelectedMatList] = useState(null);
  const [selectedMatGroup, setSelectedMatGroup] = useState(null);
  const [groupedMaterialList, setGroupedMaterialList] = useState([]);
  const [viewType, setViewType] = useState("0");
  const [productLocation, setProductLocation] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleProductLocationChange = (locationId) => {
    setProductLocation(locationId);
  };

  const handleMatListChange = (Arr) => {
    setSelectedMatList(Arr[0]);
  };

  useEffect(() => {}, [viewType]);

  const formatRecord = (data) => {
    if (!data || data.length === 0) return {};

    const groupedData = data.reduce((acc, currentItem) => {
      const { group_name } = currentItem;
      if (!acc[group_name]) {
        acc[group_name] = [];
      }
      acc[group_name].push(currentItem);
      return acc;
    }, {});

    return groupedData;
  };

  const handleMatGroupChange = (GroupId) => {
    setSelectedMatGroup(GroupId);
  };

  const handleViewTypeChange = (e) => {
    setViewType(e.target.value);
  };

  const getallMaterialList = async (e) => {
    const response = await axios.get(
      `https://eps.elkomprintama.com/get/api/store/mats`
    );
    const formattedData = formatRecord(response.data.result);
    setGroupedMaterialList(formattedData);
  };

  const getGroupedMaterialList = async (groupId) => {
    const response = await axios.get(
      `https://eps.elkomprintama.com/get/api/store/mats/groups/${groupId}`
    );
    setMatList(response.data.result);
  };

  const getMaterialListById = async (project_id) => {
    try {
      const response = await axios.get(
        `https://eps.elkomprintama.com/get/api/store/mats/${project_id}`
      );
      setMatList(response.data.result);
    } catch (error) {
      alert(error);
    }
  };

  const checkAllCurrentStock = (e) => {
    e.preventDefault();

    switch (viewType) {
      case "0":
        getallMaterialList();
        break;
      case "1":
        if (!productLocation) {
          alert("Please select Location");
          break;
        }

        getMaterialListById(selectedMatList);
        break;
      case "2":
        if (!selectedMatGroup) {
          alert("Please select a group");
          break;
        } else {
          getGroupedMaterialList(selectedMatGroup);
        }

        break;
      default:
        getallMaterialList();
    }
  };

  function applyStripedStyle(index) {
    return index % 2 === 0 ? "border-b bg-gray-50" : " bg-white"; // Apply the striped-row class to even-indexed rows
  }

  return (
    <>
      <div className=" h-full py-2 px-5 bg-gray-100  sm:rounded-lg">
        <div className="control w-full  ">
          <div className="w-full">
            <label
              className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
              htmlFor="show-option"
            >
              Show All Material
            </label>
            <select
              className={`block appearance-none w-full border  border-green-400"
              text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="show-option"
              name="show-option"
              value={viewType}
              onChange={handleViewTypeChange}
            >
              <option value={0} key="Viewoption0">
                Show All
              </option>
              <option value={1} key="Viewoption1">
                Search by Product Code or Product Name
              </option>
              <option value={2} key="Viewoption2">
                Search by Group Code
              </option>
            </select>
          </div>
        </div>
        <div className=" mb-5">
          <form onSubmit={checkAllCurrentStock}>
            {viewType === "1" ? (
              <>
                <MaterialLocationSelect
                  onChangeList={handleProductLocationChange}
                ></MaterialLocationSelect>
                <MaterialListSelect
                  onChangeList={handleMatListChange}
                  onOffSwitch={viewType}
                  location_id={productLocation}
                ></MaterialListSelect>
              </>
            ) : (
              ""
            )}

            {viewType === "2" ? (
              <MaterialGroupSelect onChangeList={handleMatGroupChange} />
            ) : (
              ""
            )}

            <Button className="my-5" fullSized type="submit">
              Show All
            </Button>
          </form>
        </div>
        <div className="relative overflow-x-auto h-[70vh] shadow-md sm:rounded-lg">
          <table className="text-sm text-left table-auto text-gray-500 dark:text-gray-400 w-full border-collapse">
            <thead className="sticky top-0 text-xs uppercase bg-gray-600 text-white dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 py-5 flex-1 border-r"
                >
                  No
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 py-5 flex-1 border-r"
                >
                  Product Code
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Product Name
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Product Group
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Unit
                </th>
                <th scope="col" className="whitespace-nowrap px-5 flex-1">
                  Product Location
                </th>
              </tr>
            </thead>
            <tbody>
              {viewType === "0"
                ? Object.entries(groupedMaterialList).map(
                    ([groupName, groupItems], groupIndex) => (
                      <React.Fragment key={`Group_${groupIndex}`}>
                        <tr
                          className="bg-gray-200 "
                          key={`Header_${groupIndex}`}
                        >
                          <td
                            className={`whitespace-nowrap px-5 py-5  border-gray-300 border-y-2`}
                          ></td>
                          <td
                            className="border-y-2 px-5  text-gray-400  border-gray-300 py-2  font-bold"
                            colSpan={5}
                          >
                            {groupName}
                          </td>
                        </tr>
                        {groupItems.map((data, index) => (
                          <tr
                            onDoubleClick={() => {
                              setShowModal(!showModal);
                              setModalData(data);
                            }}
                            className={`border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-red-50 dark:hover:bg-gray-600 ${applyStripedStyle(
                              index
                            )}`}
                            key={`Child_${groupIndex}_${index}`}
                          >
                            <td
                              className={`whitespace-nowrap px-5 py-5 border-r`}
                            >
                              {index + 1}
                            </td>
                            <td
                              className={`whitespace-nowrap px-5 py-5 border-r`}
                            >
                              {data.product_code}
                            </td>
                            <td className="whitespace-nowrap px-5 border-r">
                              {data.product_name}
                            </td>
                            <td className="whitespace-nowrap px-5 border-r">
                              {data.group_name}
                            </td>
                            <td className="whitespace-nowrap px-5 border-r">
                              {data.unit}
                            </td>
                            <td className="whitespace-nowrap px-5">
                              {data.product_location_name}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    )
                  )
                : matList.map((data, index) => (
                    <tr
                      onDoubleClick={() => {
                        setShowModal(!showModal);
                        setModalData(data);
                      }}
                      className={`py-6 text-xs border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${applyStripedStyle(
                        index
                      )}`}
                      key={data.product_id}
                    >
                      <td className="whitespace-nowrap px-5 py-5 flex-1 border-r">
                        {index + 1}
                      </td>
                      <td className="whitespace-nowrap px-5 py-5 flex-1 border-r">
                        {data.product_code}
                      </td>
                      <td className="whitespace-nowrap px-5 flex-1 border-r">
                        {data.product_name}
                      </td>
                      <td className="whitespace-nowrap px-5 flex-1 border-r">
                        {data.group_name}
                      </td>
                      <td className="whitespace-nowrap px-5 flex-1 border-r">
                        {data.unit}
                      </td>
                      <td className="whitespace-nowrap uppercase px-5 flex-1">
                        {data.product_location_name}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
      {showModal ? (
        <div
          className={`fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex flex-col justify-center items-center`}
          // id="wrapper"
          // onClick={handleClose}
        >
          <div className="w-full max-w-2xl text-end ">
            <button
              className="text-gray-700 pr-1 pb-1 font-bold text-xl"
              onClick={() => {
                setShowModal(!showModal);
              }}
            >
              X
            </button>
          </div>
          <MaterialListModal
            onClose={() => {
              setShowModal(false);
            }}
            dataSet={modalData}
            refreshData={() => {
              switch (viewType) {
                case "0":
                  getallMaterialList();
                  break;
                case "1":
                  if (!productLocation) {
                    alert("Please select Location");
                    break;
                  }

                  getMaterialListById(selectedMatList);
                  break;
                case "2":
                  if (!selectedMatGroup) {
                    alert("Please select a group");
                    break;
                  } else {
                    getGroupedMaterialList(selectedMatGroup);
                  }

                  break;
                default:
                  getallMaterialList();
              }
            }}
          ></MaterialListModal>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default MaterialList;
