import React, { useState } from "react";
import axios from "axios";
import { Button } from "flowbite-react";
import ClientList from "./ClientList";
import UpdateQuotationModal from "./UpdateQuotationModal";
import { BsFillCloudDownloadFill } from "react-icons/bs";
import PdfMake from "./PdfMake";

const QuotationList = () => {
  const [quotes, setQuotes] = useState([]);
  const [rows, setRows] = useState(0);
  const [desc, setDesc] = useState("");
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleInitialClientId = (data) => {
    setSelectedClientId(data);
  };

  const handleClientChange = (clientId) => {
    setSelectedClientId(clientId);
    setDesc("");
    console.log(selectedClientId);
  };

  const searchbyCustomer = (e) => {
    e.preventDefault();
    getQuotesById(selectedClientId);
  };

  function applyStripedStyle(index) {
    return index % 2 === 0 ? "border-b bg-gray-50" : " bg-white"; // Apply the striped-row class to even-indexed rows
  }

  const handleDescriptionInput = (e) => {
    setDesc(e.target.value);
    console.log(desc);
  };

  const getQuotesById = async (cust_id) => {
    const response = await axios.get(
      `https://eps.elkomprintama.com/get/api/quote/${cust_id}?search=${desc}`
    );
    setQuotes(response.data.result);
    setRows(response.data.totalRows[0].totalRows);
  };

  return (
    <>
      <div className="flex flex-col h-full bg-gray-200 px-5 py-5 sm:rounded-lg">
        <form onSubmit={searchbyCustomer} className="mb-2">
          <div className="flex flex-wrap -mx-3 mb-5">
            <div className="w-full md:w-1/2 px-3">
              <ClientList
                onChangeList={handleClientChange}
                onInitial={handleInitialClientId}
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                htmlFor="qt_search"
                className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
              >
                Search
              </label>
              <div className="control w-full  ">
                <input
                  className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id="qt_search"
                  name="qt_search"
                  type="text"
                  placeholder="Search Description"
                  value={desc}
                  onChange={handleDescriptionInput}
                />
              </div>
            </div>
          </div>

          <Button fullSized type="submit">
            Search Quotation
          </Button>
        </form>

        <p className="text-gray-900">Total Rows: {rows}</p>
        <div className="overflow-auto shadow-md sm:rounded-lg h-[70vh]">
          <table className="text-sm text-left text-gray-500 dark:text-gray-400 border-collapse w-full">
            <thead className="sticky top-0 text-xs text-white uppercase bg-gray-600 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 py-5 border-r"
                >
                  ID
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Download
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Date
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Customer
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Attention
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Description
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  P/N
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Size
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Material
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Colour
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Price1
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Price2
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Price3
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Price4
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Price5
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Currency
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Lead Time
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Term
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Effect Type
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  MPN
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  COO
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Admin
                </th>
              </tr>
            </thead>
            <tbody>
              {quotes.map((quote, index) => (
                <tr
                  className={`py-6 text-xs border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-red-50 dark:hover:bg-gray-600 ${applyStripedStyle(
                    index
                  )}`}
                  key={quote.quote_id}
                  onDoubleClick={() => {
                    setShowModal(!showModal);
                    setModalData(quote);
                  }}
                >
                  <td className="whitespace-nowrap px-5 py-5 border-r">
                    {quote.quote_id}
                  </td>
                  <td className="font-medium text-red-500 dark:text-red-500 hover:underline px-3 border-r">
                    <PdfMake dataSet={quote}></PdfMake>
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {new Date(quote.quote_date).toLocaleDateString()}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.nme}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.attn}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.descr}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.pn}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.size}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.mat}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.clr}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.price1}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.price2}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.price3}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.price4}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.price5}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.curr}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.lead}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.term}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.effect_type}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.mpn}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.coo}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {quote.admin}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showModal ? (
        <div
          className={`fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm  flex flex-col justify-center items-center`}
          // id="wrapper"
          // onClick={handleClose}
        >
          <div className="w-full max-w-4xl text-end  ">
            <button
              className="text-red-700 pr-1 pb-1 font-bold text-xl"
              onClick={() => {
                setShowModal(!showModal);
              }}
            >
              X
            </button>
          </div>
          <UpdateQuotationModal
            onClose={() => {
              setShowModal(false);
            }}
            dataSet={modalData}
            refreshData={() => {
              getQuotesById(selectedClientId);
            }}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default QuotationList;
