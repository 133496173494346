import React, { useState, useEffect } from "react";
import { Button } from "flowbite-react";
import axios from "axios";

function NewMaterialGroup() {
  const [groupName, setGroupName] = useState("");
  const [groupNameList, setGroupNameList] = useState([]);

  useEffect(() => {
    getGroupNameList();
  }, []);

  const getGroupNameList = async () => {
    try {
      const response = await axios.get(
        `https://eps.elkomprintama.com/get/api/store/group`
      );
      setGroupNameList(response.data.result);
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
  };

  function applyStripedStyle(index) {
    return index % 2 === 0 ? "border-b bg-gray-50" : " bg-white"; // Apply the striped-row class to even-indexed rows
  }

  const insertGroupName = async (e) => {
    try {
      const response = await axios.post(
        `https://eps.elkomprintama.com/post/api/store/group`,
        {
          mat_group_name: groupName,
        }
      );
      if (response.status === 200) {
        // Access the 'msg' property from the response data
        alert(response.data.msg); // This will display the success message
        getGroupNameList();
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.msg);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!groupName) {
      window.alert("Please fill in material group field");
      return;
    }
    const confirmation = window.confirm(
      "Are you sure you want to insert the data?"
    );

    if (confirmation) {
      insertGroupName();
      setGroupName("");
    }
  };

  return (
    <>
      <div className={`w-full  rounded-lg `}>
        <label
          className={`block uppercase text-white  mb-2 tracking-wide  text-2xl text-center  font-bold `}
        >
          New Material Group
        </label>
      </div>
      <div className="   py-2 px-5 bg-gray-200  sm:rounded-lg">
        <form className="mb-5" onSubmit={handleSubmit}>
          <div className="field w-full my-2">
            <div className="control w-full  ">
              <div className="flex flex-wrap -mx-3 mb-5">
                <div className="w-full px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                    htmlFor="grid-pn"
                  >
                    Material Group
                  </label>
                  <input
                    className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    id="grid-pn"
                    type="text"
                    placeholder="Material Group Name"
                    onChange={handleGroupNameChange}
                    value={groupName.toUpperCase()}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full ">
            <Button fullSized type="submit">
              Insert
            </Button>
          </div>
        </form>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg h-[70vh]">
          <table className="text-sm text-left table-auto text-gray-500 dark:text-gray-400 w-full border-collapse">
            <thead className="sticky top-0  text-white uppercase bg-gray-600 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 py-5 flex-1 border-r"
                >
                  Group Id
                </th>
                <th scope="col" className="whitespace-nowrap px-5 flex-1">
                  Group Name
                </th>
              </tr>
            </thead>
            <tbody>
              {groupNameList.map((job, index) => (
                <tr
                  className={`py-6  border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${applyStripedStyle(
                    index
                  )}`}
                  key={job.group_id}
                >
                  <td className="whitespace-nowrap px-5 py-5 flex-1 border-r">
                    {job.group_id}
                  </td>
                  <td className="whitespace-nowrap px-5 flex-1">
                    {job.group_name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default NewMaterialGroup;
