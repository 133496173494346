import React, { useState, useEffect } from "react";

import { Button } from "flowbite-react";
import { BsSearch } from "react-icons/bs";

import axios from "axios";

function Diecut() {
  const [dieCut, setDieCut] = useState([]);
  const [specDieCut, setSpecDieCut] = useState([]);
  const [diecutLength, setDiecutLength] = useState("");
  const [viewType, setViewType] = useState("0");
  const [diecutWidth, setDiecutWidth] = useState("");
  const [filteredDieCut, setFilteredDieCut] = useState([]);
  const [filteredSpecDieCut, setFilteredSpecDieCut] = useState([]);

  const getNormalDiecutList = async (e) => {
    const response = await axios.get(
      `https://eps.elkomprintama.com/get/api/diecut/normal`
    );
    setDieCut(response.data.result);
    setFilteredDieCut(response.data.result);
  };

  const getSpecialDiecutList = async (e) => {
    const response = await axios.get(
      `https://eps.elkomprintama.com/get/api/diecut/special`
    );
    setSpecDieCut(response.data.result);
    setFilteredSpecDieCut(response.data.result);
  };

  const checkAllCurrentStock = (e) => {
    e.preventDefault();

    switch (viewType) {
      case "0":
        getNormalDiecutList();
        break;
      case "1":
        getSpecialDiecutList();
    }
  };

  const handleViewTypeChange = (e) => {
    setViewType(e.target.value);
    setFilteredDieCut([]);
    setFilteredSpecDieCut([]);
    setDiecutLength("");
    setDiecutWidth("");
    setDieCut([]);
    setSpecDieCut([]);
  };

  function applyStripedStyle(index) {
    return index % 2 === 0 ? "border-b bg-gray-50" : " bg-white"; // Apply the striped-row class to even-indexed rows
  }
  function formatNumber(num, decimalPlaces) {
    const formattedNumber = num.toFixed(decimalPlaces);
    return formattedNumber.replace(/\.?0+$/, "");
  }

  const handleFilteredData = (e) => {
    e.preventDefault();
    if (viewType === "0") {
      if (diecutLength.trim() === "" && diecutWidth.trim() === "") {
        setFilteredDieCut(dieCut);
      } else {
        const filteredData = dieCut.filter((die) => {
          const condition1 = diecutLength
            ? die.die_p.toFixed(3).startsWith(parseFloat(diecutLength))
            : true;
          const condition2 = diecutWidth
            ? die.die_l.toFixed(3).startsWith(parseFloat(diecutWidth))
            : true;

          return condition1 && condition2;
        });
        setFilteredDieCut(filteredData);
      }
    } else {
      if (diecutLength.trim() === "" && diecutWidth.trim() === "") {
        setFilteredSpecDieCut(specDieCut);
      } else {
        const filteredSpecData = specDieCut.filter((die) => {
          const condition1 = diecutLength
            ? die.spec_p.toFixed(3).startsWith(parseFloat(diecutLength))
            : true;
          const condition2 = diecutWidth
            ? parseInt(die.spec_l)
                .toFixed(3)
                .startsWith(parseFloat(diecutWidth))
            : true;

          return condition1 && condition2;
        });
        setFilteredSpecDieCut(filteredSpecData);
      }
    }
  };

  return (
    <>
      <div className=" h-full py-2 px-5 bg-gray-100  sm:rounded-lg">
        <div className="control w-full  ">
          <div className="w-full">
            <label
              className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
              htmlFor="show-option"
            >
              Show All Diecut
            </label>
            <select
              className={`block appearance-none w-full border  border-green-400"
              text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
              id="show-option"
              name="show-option"
              value={viewType}
              onChange={handleViewTypeChange}
            >
              <option value={0} key="Viewoption0">
                Normal Diecut
              </option>
              <option value={1} key="Viewoption1">
                Special Diecut
              </option>
            </select>
          </div>
        </div>
        <div className="my-3">
          <form onSubmit={checkAllCurrentStock}>
            <Button className="" fullSized type="submit">
              Show All
            </Button>
          </form>
        </div>

        <div className="field w-full mb-2">
          <form onSubmit={handleFilteredData}>
            <div className="control w-full  ">
              <div className="flex flex-wrap -mx-3 ">
                <div className="w-full flex-1 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                    htmlFor="ndiecut_length"
                  >
                    Length
                  </label>
                  <input
                    className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    id="ndiecut_length"
                    type="text"
                    placeholder="Length"
                    value={diecutLength}
                    onChange={(e) => {
                      setDiecutLength(e.target.value);
                    }}
                  />
                </div>

                <div className="w-full  flex-1 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                    htmlFor="ndiecut_width"
                  >
                    Width
                  </label>
                  <input
                    className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    id="ndiecut_width"
                    type="text"
                    placeholder="Width"
                    value={diecutWidth}
                    onChange={(e) => {
                      setDiecutWidth(e.target.value);
                    }}
                  />
                </div>
                <div className="w-full  flex-1 px-3">
                  <Button className="mt-6" fullSized type="submit">
                    <BsSearch className="mr-2 h-5 w-5" />
                    Find
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
        {viewType === "0" ? (
          <div className="relative overflow-x-auto h-[70vh] shadow-md sm:rounded-lg">
            <table className="text-sm text-left table-auto text-gray-500 dark:text-gray-400 w-full border-collapse">
              <thead className="sticky top-0 text-xs uppercase bg-gray-600 text-white dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 py-5 flex-1 border-r"
                  >
                    No
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 py-5 flex-1 border-r"
                  >
                    Length
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 flex-1 border-r"
                  >
                    Width
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 flex-1 border-r"
                  >
                    Unit
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 flex-1 border-r"
                  >
                    Radius
                  </th>
                  <th scope="col" className="whitespace-nowrap px-5 flex-1">
                    Ups
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredDieCut.map((dieCut, index) => (
                  <tr
                    className={`py-6 text-xs border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-red-50 dark:hover:bg-gray-600 ${applyStripedStyle(
                      index
                    )}`}
                    key={dieCut.die_id}
                  >
                    <td className="whitespace-nowrap px-5 py-5 border-r">
                      {dieCut.die_id}
                    </td>
                    <td className="whitespace-nowrap px-5 py-5 border-r">
                      {formatNumber(dieCut.die_p, 3)}
                    </td>
                    <td className="whitespace-nowrap px-5 border-r">
                      {formatNumber(dieCut.die_l, 3)}
                    </td>
                    <td className="whitespace-nowrap px-5 border-r">
                      {dieCut.die_unit}
                    </td>
                    <td className="whitespace-nowrap px-5 border-r">
                      {dieCut.die_r}
                    </td>
                    <td className="whitespace-nowrap px-5 border-r">
                      {dieCut.die_ups}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="relative overflow-x-auto h-[70vh] shadow-md sm:rounded-lg">
            <table className="text-sm text-left table-auto text-gray-500 dark:text-gray-400 w-full border-collapse">
              <thead className="sticky top-0 text-xs uppercase bg-gray-600 text-white dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 py-5 flex-1 border-r"
                  >
                    No
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 py-5 flex-1 border-r"
                  >
                    Model
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 flex-1 border-r"
                  >
                    Length
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 flex-1 border-r"
                  >
                    Width
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 flex-1 border-r"
                  >
                    Unit
                  </th>
                  <th scope="col" className="whitespace-nowrap px-5 flex-1">
                    Ups
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredSpecDieCut.map((dieCut, index) => (
                  <tr
                    className={`py-6 text-xs border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-red-50 dark:hover:bg-gray-600 ${applyStripedStyle(
                      index
                    )}`}
                    key={dieCut.spec_id}
                  >
                    <td className="whitespace-nowrap px-5 py-5 border-r">
                      {dieCut.spec_id}
                    </td>
                    <td className="whitespace-nowrap px-5 py-5 border-r">
                      {dieCut.spec_model}
                    </td>
                    <td className="whitespace-nowrap px-5 border-r">
                      {dieCut.spec_p}
                    </td>
                    <td className="whitespace-nowrap px-5 border-r">
                      {dieCut.spec_l}
                    </td>
                    <td className="whitespace-nowrap px-5 border-r">
                      {dieCut.spec_unit}
                    </td>
                    <td className="whitespace-nowrap px-5 border-r">
                      {dieCut.spec_ups}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}

export default Diecut;
