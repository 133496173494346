import React, { useState, useEffect } from "react";
import { Button } from "flowbite-react";
import MaterialListSelect from "./MaterialListSelect";
import MaterialLocationSelect from "./MaterialLocationSelect";
import axios from "axios";
import { useSelector } from "react-redux";

function StockInOutModal({ dataSet, onClose, refreshData }) {
  const stockType = ["PLEASE SELECT", "Stock In", "Stock Out"];
  const [selectedMatList, setSelectedMatList] = useState(null);
  const [selectedMatListName, setSelectedMatListName] = useState("");
  const [stockQty, setStockQty] = useState("");
  const [remark, setRemark] = useState("");
  const [transFor, setTransFor] = useState("");
  const { user } = useSelector((state) => state.auth);
  const [productLocation, setProductLocation] = useState();
  const [currStock, setCurrStock] = useState(null);
  const [stockTypes, setStockType] = useState(stockType[0]);
  const [initialStockQtySet, setInitialStockQtySet] = useState(false);

  const handleStockTypeChange = (e) => {
    setInitialStockQtySet(!initialStockQtySet);
    setStockType(e.target.value);
  };

  useEffect(() => {
    if (dataSet) {
      // Set values from dataSet if stockQty is empty

      setStockQty(
        dataSet.transaction_type == "Stock Out"
          ? dataSet.stock_out
          : dataSet.stock_in
      );
      setSelectedMatList(dataSet.product_id);
      setRemark(dataSet.transaction_remark || "");
      setTransFor(dataSet.transaction_for || "");
      setStockType(dataSet.transaction_type);
    }
  }, [dataSet]);

  const handleProductLocationChange = (locationId) => {
    setProductLocation(locationId);
  };

  const handleMatListChange = (Arr) => {
    setSelectedMatList(Arr[0]);
    setSelectedMatListName(Arr[1]);

    getCurrentStock(Arr[0]);
  };

  const handleStockInQTYInputChange = (e) => {
    const input = e.target.value;
    const regex = /^[0-9\b]+$/; // Regex to allow only numbers

    if (regex.test(input)) {
      setStockQty(input);
    }
  };

  const voidTransactions = async () => {
    try {
      const response = await axios.patch(
        `https://eps.elkomprintama.com/patch/api/store/transaction/update`,
        {
          stockIn: 0,
          stockOut: 0,
          transactionType: stockTypes,
          transactionBy: user.username,
          transactionFor: transFor,
          transactionRemark: "[VOID] " + remark,
          transactionId: dataSet.transaction_id,
        }
      );

      if (response.status === 200) {
        alert(response.data.msg); // This will display the success message
        onClose();
        refreshData();
      }
    } catch (error) {
      console.log("Error:", error); // Log the entire error object for detailed information

      // Check if the error has a response (from the server)
      if (error.response) {
        console.log("Response error:", error.response.data.msg);
      } else if (error.request) {
        // The request was made but no response was received
        console.log("Request error:", error.request);
      } else {
        // Something happened in setting up the request that triggered an error
        console.log("General error:", error.message);
      }
    }
  };

  const handleRemarkInputChange = (e) => {
    const uppercaseValue = e.target.value.toUpperCase();
    setRemark(uppercaseValue);
  };

  const handleCustomerInputChange = (e) => {
    const uppercaseValue = e.target.value.toUpperCase();
    setTransFor(uppercaseValue);
  };

  const updateTransRecord = async (e) => {
    try {
      const response = await axios.patch(
        `https://eps.elkomprintama.com/patch/api/store/transaction/update`,
        {
          stockIn: stockTypes === "Stock In" ? stockQty : 0,
          stockOut: stockTypes === "Stock Out" ? stockQty : 0,
          transactionType: stockTypes,
          transactionBy: user.username,
          transactionFor: transFor,
          transactionRemark: remark,
          transactionId: dataSet.transaction_id,
        }
      );

      if (response.status === 200) {
        alert(response.data.msg); // This will display the success message
        onClose();
        refreshData();
      }
    } catch (error) {
      console.log("Error:", error); // Log the entire error object for detailed information

      // Check if the error has a response (from the server)
      if (error.response) {
        console.log("Response error:", error.response.data.msg);
      } else if (error.request) {
        // The request was made but no response was received
        console.log("Request error:", error.request);
      } else {
        // Something happened in setting up the request that triggered an error
        console.log("General error:", error.message);
      }
    }
  };

  const getCurrentStock = async (product_id) => {
    if (!product_id) {
      setCurrStock(null);
      return;
    }

    try {
      const response = await axios.get(
        `https://eps.elkomprintama.com/get/api/store/currentallstock/${product_id}`
      );
      if (response.status === 200) {
        // Access the 'msg' property from the response data
        setCurrStock(response.data.result); // This will display the success message
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.msg);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      stockTypes === "Stock Out" &&
      Number(stockQty) > (currStock.current_stock + dataSet.stock_out || 0)
    ) {
      window.alert("QTY STOCK OUT MELEBIHI QTY STOCK YANG ADA! PLEASE CHECK");
      return;
    }

    const confirmation = window.confirm(
      "Are you sure you want to Update the data?!"
    );
    if (confirmation) {
      updateTransRecord();
    }
  };

  return (
    <div className="relative w-full max-w-2xl bg-gray-100 overflow-x-auto shadow-md px-5 rounded-lg">
      <form className="my-5" onSubmit={handleSubmit}>
        <MaterialLocationSelect
          dataSet={dataSet}
          selectedLocation={productLocation}
          onChangeList={handleProductLocationChange}
          locked={true}
        ></MaterialLocationSelect>
        <MaterialListSelect
          dataSet={dataSet}
          onChangeList={handleMatListChange}
          location_id={productLocation}
          locked={true}
        ></MaterialListSelect>

        <div className="w-full mb-5 ">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="stock_type"
          >
            Stock Type
          </label>
          <select
            className="block appearance-none w-full border border-green-400 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="stock_type"
            name="stock_type"
            onChange={handleStockTypeChange}
            value={stockTypes}
          >
            {Array.isArray(stockType) ? (
              stockType.map((data, index) => (
                <option value={data} key={`sttype-${index}`}>
                  {data}
                </option>
              ))
            ) : (
              <option value="" key="empty-option">
                Loading... {/* or any other placeholder */}
              </option>
            )}
          </select>
        </div>

        <div className="control w-full  ">
          <div className="flex flex-wrap -mx-3 mb-5">
            <div className="w-full md:w-1/2  px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor={`qty_${stockType}`}
              >
                QTY
              </label>
              <input
                className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id={`qty_${stockType}`}
                type="text"
                placeholder="QTY"
                value={stockQty}
                onChange={handleStockInQTYInputChange}
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="qty_stock_in"
              >
                Stock Left
              </label>

              <input
                className={`appearance-none block w-full border bg-white border-gray-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ${
                  currStock && currStock.current_stock === 0
                    ? "text-red-500"
                    : ""
                }`}
                id="qty_stock_in"
                type="text"
                placeholder="QTY"
                disabled
                readOnly
                value={`${
                  currStock
                    ? `${currStock.current_stock} ${currStock.unit}`
                    : ""
                }`}
              />
            </div>
          </div>
        </div>
        <div className="control w-full  ">
          <div className="flex flex-wrap -mx-3 mb-5">
            <div className="w-full px-3 ">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="stock_in_remark"
              >
                REMARK / OPERATOR
              </label>
              <input
                className="appearance-none uppercase block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="stock_in_remark"
                type="text"
                placeholder="REMARK / OPERATOR"
                value={remark}
                onChange={handleRemarkInputChange}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-5">
            <div className="w-full px-3 ">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="stock_in_remark"
              >
                CUSTOMER
              </label>
              <input
                className="appearance-none uppercase block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="stock_in_customer"
                type="text"
                placeholder="Customer"
                value={transFor}
                onChange={handleCustomerInputChange}
              />
            </div>
          </div>
        </div>
        <div className="w-full mb-3">
          <Button color="blue" fullSized type="submit">
            Update
          </Button>
        </div>
        <div className="w-full ">
          <Button color="failure" fullSized onClick={voidTransactions}>
            VOID
          </Button>
        </div>
      </form>
    </div>
  );
}

export default StockInOutModal;
