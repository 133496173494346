import React, { useState, useEffect } from "react";
import { Button } from "flowbite-react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

function DocumentModal({ dataSet, onClose, refreshData }) {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [inputDateSelected, setInputDateSelected] = useState(new Date());

  useEffect(() => {
    if (dataSet) {
      // Remove the file extension from dataSet.filename
      const fileWithoutExtension = dataSet.filename
        .split(".")
        .slice(0, -1)
        .join(".");
      setFileName(fileWithoutExtension.toUpperCase());
      setInputDateSelected(new Date(dataSet.issue_date));
    }
  }, [dataSet]);

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      alert("Please select a file.");
      return;
    } else if (!fileName) {
      alert("Please input name");
      return;
    }
    const confirmation = window.confirm(
      "Are you sure you want to insert the data?"
    );

    if (confirmation) {
      try {
        // Upload the new file first
        const formData = new FormData();
        formData.append("file", file);
        const fileExtension = file.name.split(".").pop();

        // Append custom filename with the file extension
        formData.append("customFileName", fileName + "." + fileExtension);
        formData.append("fileId", dataSet.id);
        formData.append("issue_date", format(inputDateSelected, "yyyy-MM-dd"));

        // Determine the URL based on dataSet.type
        let url;
        if (dataSet.type === "icp") {
          url = "https://eps.elkomprintama.com/icp/patch";
        } else if (dataSet.type === "msds") {
          url = "https://eps.elkomprintama.com/msds/patch";
        } else if (dataSet.type === "spec") {
          url = "https://eps.elkomprintama.com/spec/patch";
        } else {
          alert("Unknown dataSet type.");
          return;
        }

        const uploadResponse = await axios.patch(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (uploadResponse.status === 200) {
          // File upload successful, proceed with deleting the previous file
          const deleteResponse = await axios.delete(
            `https://eps.elkomprintama.com/deleteFile?filePath=${encodeURIComponent(
              dataSet.path
            )}`
          );

          if (deleteResponse.status === 200) {
            // File deletion successful
            alert("File uploaded and previous file deleted successfully.");
            refreshData();
            onClose();
          } else {
            // Handle the case where file deletion failed
            console.error(
              "File deletion failed. Status:",
              deleteResponse.status
            );
          }
        } else {
          // Handle the case where file upload failed
          console.error("File upload failed. Status:", uploadResponse.status);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };

  return (
    <div className="relative max-w-2xl w-full  bg-gray-100 shadow-md px-5 rounded-lg">
      <form className="my-5" onSubmit={handleUpload}>
        <div className="field w-full my-2">
          <div className="flex flex-wrap -mx-3 mb-5">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor={`grid-uploadedit-filename`}
              >
                Material Name
              </label>
              <input
                className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id={`grid-uploadedit-filename`}
                type="text"
                placeholder="Material"
                onChange={handleFileNameChange}
                value={fileName.toUpperCase()}
              />
            </div>
          </div>
        </div>
        <div className="field w-full ">
          <div className="flex flex-wrap -mx-3 mb-5">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor={`grid-uploadeditfile-filename`}
              >
                Choose a file
              </label>
              <input
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="file_input"
                onChange={handleFileChange}
                type="file"
              ></input>
            </div>
          </div>
        </div>
        <div className="field w-full ">
          <div className="flex flex-wrap -mx-3 mb-5">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor={`grid-update-issuedate`}
              >
                Issue Date
              </label>
              <DatePicker
                name={`grid-update-issuedate`}
                id={`grid-update-issuedate`}
                className={`block appearance-none w-full border  border-green-400
                  text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                wrapperClassName={"w-full  "}
                selected={inputDateSelected}
                onChange={(e) => {
                  setInputDateSelected(e);
                }}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
        </div>
        <div className="w-full ">
          <Button fullSized type="submit">
            Update
          </Button>
        </div>
      </form>
    </div>
  );
}

export default DocumentModal;
