import React from "react";

const RadioButton = ({ radioData, onchangeDate }) => {
  return (
    <div className="flex flex-row w-full  px-3 mb-6 ">
      <div className="flex-auto ">
        <input
          id="default-radio-1"
          type="radio"
          value="1"
          name="default-radio"
          onChange={onchangeDate}
          checked={radioData.toString() === "1"}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
        />
        <label
          htmlFor="default-radio-1"
          className="ml-2 text-sm font-medium text-blue-500 dark:text-gray-300"
        >
          Jobsheet
        </label>
      </div>
      <div className=" flex-auto">
        <input
          id="default-radio-2"
          type="radio"
          value="2"
          name="default-radio"
          onChange={onchangeDate}
          checked={radioData.toString() === "2"}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
        />
        <label
          htmlFor="default-radio-2"
          className="ml-2 text-sm font-medium text-green-500 dark:text-gray-300"
        >
          On Process
        </label>
      </div>
      <div className=" flex-auto">
        <input
          id="default-radio-3"
          type="radio"
          value="3"
          name="default-radio"
          onChange={onchangeDate}
          checked={radioData.toString() === "3"}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
        />
        <label
          htmlFor="default-radio-3"
          className="ml-2 text-sm font-medium text-yellow-500 dark:text-gray-300"
        >
          QC Check
        </label>
      </div>

      <div className=" flex-auto">
        <input
          id="default-radio-5"
          type="radio"
          value="4"
          name="default-radio"
          onChange={onchangeDate}
          checked={radioData.toString() === "4"}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
        />
        <label
          htmlFor="default-radio-5"
          className="ml-2 text-sm font-medium text-red-500 dark:text-gray-300"
        >
          Rework
        </label>
      </div>
    </div>
  );
};

export default RadioButton;
