import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateTimePicker = ({
  firstDateValue,
  onChangeFirstDate,
  secondDateValue,
  onChangeSecondDate,
  firstLabel,
  secondLabel,
}) => {
  return (
    <div className="flex flex-wrap -mx-3 mb-5">
      <div className="w-full md:w-1/2 px-3">
        <label
          className="block uppercase tracking-wide center text-gray-800 text-xs font-bold mb-2"
          htmlFor={`date-${firstLabel}`}
        >
          {firstLabel}
        </label>
        <DatePicker
          name={`date-${firstLabel}`}
          id={`date-${firstLabel}`}
          className="w-full rounded-lg"
          wrapperClassName={"w-full  "}
          selected={firstDateValue}
          onChange={onChangeFirstDate}
          dateFormat="dd/MM/yyyy"
        />
      </div>

      <div className="w-full md:w-1/2 px-3">
        <label
          className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
          htmlFor={`date-${secondLabel}`}
        >
          {secondLabel}
        </label>
        <DatePicker
          name={`date-${secondLabel}`}
          id={`date-${secondLabel}`}
          className="w-full rounded-lg"
          wrapperClassName={"w-full  "}
          selected={secondDateValue}
          onChange={onChangeSecondDate}
          dateFormat="dd/MM/yyyy"
        />
      </div>
    </div>
  );
};

export default DateTimePicker;
