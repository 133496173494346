import React, { useState, useEffect } from "react";
import MaterialListSelect from "./MaterialListSelect";
import MaterialGroupSelect from "./MaterialGroupSelect";
import MaterialLocationSelect from "./MaterialLocationSelect";
import { Button, ToggleSwitch } from "flowbite-react";
import axios from "axios";

function MaterialCurrentStock() {
  const [selectedMatList, setSelectedMatList] = useState(null);
  const [selectedMatGroup, setSelectedMatGroup] = useState(null);
  const [currentStock, setCurrentStock] = useState([]);
  const [currentGroupedStock, setCurrentGroupedStock] = useState([]);
  const [viewType, setViewType] = useState("0");
  const [productLocation, setProductLocation] = useState("");
  const [switch1, setSwitch1] = useState(true);

  const handleProductLocationChange = (locationId) => {
    setProductLocation(locationId);
  };

  const handleMatListChange = (Arr) => {
    setSelectedMatList(Arr[0]);
  };

  useEffect(() => {}, [viewType]);

  const formatRecord = (data) => {
    if (!data || data.length === 0) return {};

    const groupedData = data.reduce((acc, currentItem) => {
      const { group_name } = currentItem;
      if (!acc[group_name]) {
        acc[group_name] = [];
      }
      acc[group_name].push(currentItem);
      return acc;
    }, {});

    return groupedData;
  };

  const handleMatGroupChange = (GroupId) => {
    setSelectedMatGroup(GroupId);
  };

  const handleViewTypeChange = (e) => {
    setViewType(e.target.value);
    setCurrentStock([]);
    setCurrentGroupedStock([]);
    setSelectedMatList(null);
    setSelectedMatGroup(null);
  };

  const getallCurrentStock = async (statusSwitch) => {
    const response = await axios.get(
      `https://eps.elkomprintama.com/get/api/store/currentallstock`
    );
    const responseResult = response.data.result;

    if (statusSwitch) {
      const formattedData = formatRecord(responseResult);
      setCurrentGroupedStock(formattedData);
    } else {
      const filteredCurrentStock = responseResult.filter(
        (item) => item.current_stock > 0
      );
      const formattedData = formatRecord(filteredCurrentStock);
      setCurrentGroupedStock(formattedData);
    }
  };

  const getCurrentStockByGroupId = async (groupId, statusSwitch) => {
    const response = await axios.get(
      `https://eps.elkomprintama.com/get/api/store/currentallstockbygroup/${groupId}`
    );
    const responseResult = response.data.result;

    if (statusSwitch) {
      setCurrentStock(responseResult);
    } else {
      const filteredCurrentStock = responseResult.filter(
        (item) => item.current_stock > 0
      );
      setCurrentStock(filteredCurrentStock);
    }
  };

  const getCurrentStockById = async (project_id) => {
    try {
      const response = await axios.get(
        `https://eps.elkomprintama.com/get/api/store/currentallstock/${project_id}`
      );
      const data = response.data.result;

      if (typeof data === "object" && !Array.isArray(data)) {
        setCurrentStock([data]);
      } else {
        // Handle other cases if needed
      }
    } catch (error) {
      // Handle API request errors
    }
  };

  const checkAllCurrentStock = (e) => {
    e.preventDefault();

    switch (viewType) {
      case "0":
        getallCurrentStock(switch1);
        break;
      case "1":
        if (!productLocation) {
          alert("Please select Location");
          break;
        }

        getCurrentStockById(selectedMatList);
        break;
      case "2":
        if (!selectedMatGroup) {
          alert("Please select a group");
          break;
        } else {
          getCurrentStockByGroupId(selectedMatGroup, switch1);
        }

        break;
      default:
        getallCurrentStock();
    }
  };

  function applyStripedStyle(index) {
    return index % 2 === 0 ? "border-b bg-gray-50" : " bg-white"; // Apply the striped-row class to even-indexed rows
  }

  return (
    <>
      <div className=" h-full py-2 px-5 bg-gray-100  sm:rounded-lg">
        <div className="control w-full  ">
          <div className="w-full">
            <div className="flex flex-wrap -mx-3 ">
              <div
                className={`w-full ${viewType !== "1" ? "md:w-5/6" : ""} px-3`}
              >
                <label
                  className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                  htmlFor="show-option"
                >
                  Show Current Stock
                </label>
                <select
                  className={`block appearance-none w-full border  border-green-400"
              text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                  id="show-option"
                  name="show-option"
                  value={viewType}
                  onChange={handleViewTypeChange}
                >
                  <option value={0} key="Viewoption0">
                    Show All
                  </option>
                  <option value={1} key="Viewoption1">
                    Search by Product Code or Product Name
                  </option>
                  <option value={2} key="Viewoption2">
                    Search by Group Code
                  </option>
                </select>
              </div>
              {viewType !== "1" && (
                <div className={`w-full md:w-1/6 px-3 pt-8 `}>
                  <input
                    type="checkbox"
                    checked={switch1}
                    onChange={() => {
                      setSwitch1(!switch1);
                    }}
                    className="mx-2"
                  />
                  <label className="text-gray-900">Show Empty Stock</label>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className=" mb-5">
          <form onSubmit={checkAllCurrentStock}>
            {viewType === "1" ? (
              <>
                <MaterialLocationSelect
                  onChangeList={handleProductLocationChange}
                ></MaterialLocationSelect>
                <MaterialListSelect
                  onChangeList={handleMatListChange}
                  onOffSwitch={viewType}
                  location_id={productLocation}
                ></MaterialListSelect>
              </>
            ) : (
              ""
            )}

            {viewType === "2" ? (
              <MaterialGroupSelect onChangeList={handleMatGroupChange} />
            ) : (
              ""
            )}

            <Button className="my-5" fullSized type="submit">
              Check Stock
            </Button>
          </form>
        </div>
        <div className="relative overflow-x-auto  shadow-md sm:rounded-lg h-[70vh]">
          <table className="text-sm text-left table-auto text-gray-500 dark:text-gray-400 w-full border-collapse">
            <thead className="sticky top-0 text-xs uppercase bg-gray-600 text-white dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 py-5 border-r"
                >
                  No
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Product Name
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Product Code
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Current Stock
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Unit
                </th>
              </tr>
            </thead>
            <tbody>
              {viewType === "0"
                ? Object.entries(currentGroupedStock).map(
                    ([groupName, groupItems], groupIndex) => (
                      <React.Fragment key={`Group_${groupIndex}`}>
                        <tr
                          className="bg-gray-200 "
                          key={`Header_${groupIndex}`}
                        >
                          <td
                            className={`whitespace-nowrap px-5 py-5 border-r`}
                          ></td>
                          <td
                            className="border-r px-5 text-gray-400 border-gray-300 py-2 font-bold"
                            colSpan={4}
                          >
                            {groupName}
                          </td>
                        </tr>
                        {groupItems.map((data, index) => (
                          <tr
                            className={`border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-red-50 dark:hover:bg-gray-600 ${applyStripedStyle(
                              index
                            )}`}
                            key={`Child_${groupIndex}_${index}`}
                          >
                            <td
                              className={`whitespace-nowrap px-5 py-5 border-r`}
                            >
                              {index + 1}
                            </td>
                            <td className={`whitespace-nowrap px-5 border-r`}>
                              {data.product_name}
                            </td>
                            <td className="whitespace-nowrap px-5 border-r">
                              {data.product_code}
                            </td>
                            <td className="whitespace-nowrap px-5 border-r">
                              {data.current_stock}
                            </td>
                            <td className="whitespace-nowrap px-5 border-r">
                              {data.unit}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    )
                  )
                : currentStock.map((data, index) => (
                    <tr
                      className={`border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-red-50 dark:hover:bg-gray-600 ${applyStripedStyle(
                        index
                      )}`}
                      key={"CS " + index}
                    >
                      <td className="whitespace-nowrap px-5 py-5 border-r">
                        {index + 1}
                      </td>
                      <td className="whitespace-nowrap px-5 border-r">
                        {data.product_name}
                      </td>
                      <td className="whitespace-nowrap px-5 border-r">
                        {data.product_code}
                      </td>
                      <td className="whitespace-nowrap px-5 border-r">
                        {data.current_stock}
                      </td>
                      <td className="whitespace-nowrap px-5 border-r">
                        {data.unit}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default MaterialCurrentStock;
