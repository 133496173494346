import React, { useState } from "react";
import { Datepicker } from "flowbite-react";
import { Button } from "flowbite-react";
import axios from "axios";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

function FinishGoodModal({ isVisible, onClose, orderData }) {
  const [dlvrDate, setDlvrDate] = useState("");
  const [finQty, setFinQty] = useState("");
  const navigate = useNavigate();

  if (!isVisible) return null;

  const handleFGInputDateChange = (date) => {
    setDlvrDate(format(new Date(date), "yyyy-MM-dd"));
  };

  const handleFinQtyChange = (e) => {
    setFinQty(e.target.value);
  };

  const finishOrder = async (productId) => {
    try {
      await axios.post(`https://eps.elkomprintama.com/post/api/fin_orders/`, {
        custId: orderData.cust_id,
        jobId: orderData.job_id,
        date: orderData.date,
        qty: orderData.qty,
        poNum: orderData.po_num,
        dlvry: orderData.dlvr_date,
        opr: orderData.opr_id,
        qc: orderData.qc_id,
        fdate: dlvrDate,
        fqty: finQty,
        orderId: orderData.order_id,
      });
      alert("success");
      navigate("/orderlist");
      onClose();
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <div
        className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex flex-col justify-center items-center"
        // id="wrapper"
        // onClick={handleClose}
      >
        <div className="w-full max-w-2xl text-end ">
          <button
            onClick={() => {
              onClose();
            }}
            className="text-gray-700 pr-1 pb-1 font-bold text-xl "
          >
            X
          </button>
        </div>

        <div className=" bg-white w-full max-w-2xl  rounded-lg flex flex-col">
          <form className="w-full max-w-2xl p-10 " onSubmit={finishOrder}>
            <p className="text-center text-3xl font-bold mb-5 ">
              Move to Finish Goods?
            </p>
            <div className="flex flex-wrap -mx-3 mb-5">
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-finish-date"
                >
                  Finish Date
                </label>
                <Datepicker
                  id="grid-finish-fgdate"
                  name="grid-finish-fgdate"
                  onSelectedDateChanged={handleFGInputDateChange}
                  value={dlvrDate}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="finishqty"
                >
                  Finish Qty
                </label>
                <input
                  onChange={handleFinQtyChange}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="finishqty"
                  name="finishqty"
                  type="text"
                  placeholder="Qty"
                />
              </div>
            </div>
            <div className="w-full ">
              <Button fullSized type="submit">
                Finish
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default FinishGoodModal;
