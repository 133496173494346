import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginUser, reset } from "../features/authSlice";
import { Button, Label, TextInput } from "flowbite-react";
import { BsPersonCircle } from "react-icons/bs";
import { getMe } from "../features/authSlice.js";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (user || isSuccess) {
      navigate("/dashboard");
    }
    dispatch(reset());
  }, [user, isSuccess, dispatch, navigate]);

  const Auth = (e) => {
    e.preventDefault();
    dispatch(LoginUser({ username, password }));
  };

  return (
    <div
      style={{
        background: "linear-gradient(to top, #4481eb 0%, #04befe 100%)",
      }}
      className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex flex-col justify-center items-center"
    >
      <div className=" bg-white relative max-w-2xl rounded-lg flex flex-col">
        <BsPersonCircle
          className={`bg-white text-sky-600 text-7xl rounded-full -top-9 right-40 absolute border 
            }`}
        />
        <form
          onSubmit={Auth}
          className="flex max-w-2xl w-96 p-10 flex-col gap-4"
        >
          {isError && <p className="has-text-centered">{message}</p>}
          <div>
            <div className="mb-2 max-w-lg block">
              <Label htmlFor="emailUser" value="Username" />
            </div>
            <TextInput
              id="emailUser"
              name="emailUser"
              placeholder="Username"
              required
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="password_user" value="Your password" />
            </div>
            <TextInput
              name="password_user"
              id="password_user"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <Button type="submit"> {isLoading ? "Loading..." : "Login"}</Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
