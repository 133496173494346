import React, { useState } from "react";
import { Button } from "flowbite-react";
import MaterialListSelect from "./MaterialListSelect";
import MaterialLocationSelect from "./MaterialLocationSelect";
import axios from "axios";
import { useSelector } from "react-redux";
import { TbTrashX } from "react-icons/tb";

function StockInOut({ stockType }) {
  const [selectedMatList, setSelectedMatList] = useState(null);
  const [selectedMatListName, setSelectedMatListName] = useState("");
  const [stockQty, setStockQty] = useState("");
  const [remark, setRemark] = useState("");
  const [transFor, setTransFor] = useState("");
  const { user } = useSelector((state) => state.auth);
  const [tempArray, setTempArray] = useState([]);
  const [productLocation, setProductLocation] = useState("0");
  const [currStock, setCurrStock] = useState(null);

  const handleProductLocationChange = (locationId) => {
    setProductLocation(locationId);
  };

  const handleMatListChange = (Arr) => {
    setSelectedMatList(Arr[0]);
    setSelectedMatListName(Arr[1]);
    setStockQty("");
    getCurrentStock(Arr[0]);
  };

  function applyStripedStyle(index) {
    return index % 2 === 0 ? "border-b bg-gray-50" : " bg-white"; // Apply the striped-row class to even-indexed rows
  }

  const handleStockInQTYInputChange = (e) => {
    const input = e.target.value;
    const regex = /^[0-9\b]+$/; // Regex to allow only numbers

    if (input === "" || regex.test(input)) {
      setStockQty(input);
    }
  };

  const handleRemarkInputChange = (e) => {
    const uppercaseValue = e.target.value.toUpperCase();
    setRemark(uppercaseValue);
  };

  const handleCustomerInputChange = (e) => {
    const uppercaseValue = e.target.value.toUpperCase();
    setTransFor(uppercaseValue);
  };

  const addObject = () => {
    if (!stockQty || !selectedMatList || !selectedMatListName) {
      window.alert("Please fill in all required fields");
      return;
    }

    if (
      stockType === "Stock Out" &&
      Number(stockQty) > (currStock.current_stock || 0)
    ) {
      window.alert("STOCK OUT QTY EXCEEDS AVAILABLE STOCK, PLEASE CHECK");
      return;
    }

    const newObject = {
      no: tempArray.length + 1,
      product_id: selectedMatList,
      product_name: selectedMatListName,
      temp_qty: stockQty,
      stock_in: stockType === "Stock In" ? stockQty : 0,
      stock_out: stockType === "Stock Out" ? stockQty : 0,
      transaction_type: stockType,
      transaction_for: transFor,
      transaction_by: user.username,
      transaction_remark: remark,
    };

    setTempArray([...tempArray, newObject]);
    setStockQty("");
  };

  const deleteObject = (idToRemove) => {
    setTempArray(tempArray.filter((item) => item.no !== idToRemove));
  };

  const insertStock = async (e) => {
    try {
      const response = await axios.post(
        `https://eps.elkomprintama.com/post/api/store/stocks`,
        { transactions: tempArray }
      );
      if (response.status === 200) {
        // Access the 'msg' property from the response data
        alert(response.data.msg); // This will display the success message
        setProductLocation("0");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.msg);
      }
    }
  };

  const getCurrentStock = async (product_id) => {
    if (!product_id) {
      setCurrStock(null);
      return;
    }

    try {
      const response = await axios.get(
        `https://eps.elkomprintama.com/get/api/store/currentallstock/${product_id}`
      );
      if (response.status === 200) {
        // Access the 'msg' property from the response data
        setCurrStock(response.data.result); // This will display the success message
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.msg);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (tempArray.length > 0) {
      const confirmation = window.confirm(
        "Are you sure you want to insert the data?"
      );
      if (confirmation) {
        insertStock();
        setRemark("");
        setStockQty("");
        setTempArray([]);
      }
    } else {
      window.alert("Please add data");
      return;
    }
  };

  return (
    <>
      <div
        className={`w-full  rounded-lg ${
          stockType === "Stock In" ? "bg-green-500 text-white" : "bg-red-500"
        }`}
      >
        <label
          className={`block uppercase text-white  mb-2 tracking-wide  text-5xl text-center  font-bold `}
        >
          {stockType}
        </label>
      </div>
      <div
        className={`relative h-full  bg-gray-100 overflow-x-auto shadow-md px-5 rounded-lg `}
      >
        <form className="my-5" onSubmit={handleSubmit}>
          <MaterialLocationSelect
            selectedLocation={productLocation}
            onChangeList={handleProductLocationChange}
          ></MaterialLocationSelect>
          <MaterialListSelect
            onChangeList={handleMatListChange}
            location_id={productLocation}
          ></MaterialListSelect>
          <div className="control w-full  ">
            <div className="flex flex-wrap -mx-3 mb-5">
              <div className="w-full md:w-1/2  px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor={`qty_${stockType}`}
                >
                  QTY {stockType}
                </label>
                <input
                  className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id={`qty_${stockType}`}
                  type="text"
                  placeholder="QTY"
                  value={stockQty}
                  onChange={handleStockInQTYInputChange}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="qty_stock_in"
                >
                  Stock Left
                </label>

                <input
                  className={`appearance-none block w-full border bg-white border-gray-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white ${
                    currStock && currStock.current_stock === 0
                      ? "text-red-500"
                      : ""
                  }`}
                  id="qty_stock_in"
                  type="text"
                  placeholder="QTY"
                  disabled
                  readOnly
                  value={`${
                    currStock
                      ? `${currStock.current_stock} ${currStock.unit}`
                      : ""
                  }`}
                />
              </div>
            </div>
          </div>
          <div className="control w-full  ">
            <div className="flex flex-wrap -mx-3 mb-5">
              <div className="w-full px-3 ">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="stock_in_remark"
                >
                  REMARK / OPERATOR
                </label>
                <input
                  className="appearance-none uppercase block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id="stock_in_remark"
                  type="text"
                  placeholder="REMARK / OPERATOR"
                  value={remark}
                  onChange={handleRemarkInputChange}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-5">
              <div className="w-full px-3 ">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="stock_in_remark"
                >
                  CUSTOMER
                </label>
                <input
                  className="appearance-none uppercase block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id="stock_in_customer"
                  type="text"
                  placeholder="Customer"
                  value={transFor}
                  onChange={handleCustomerInputChange}
                />
              </div>
            </div>
          </div>
          <div className="w-full ">
            <Button color="gray" fullSized onClick={addObject}>
              ADD
            </Button>
          </div>
          <div className="relative overflow-x-auto shadow-md my-6 sm:rounded-lg">
            <table className="text-sm text-left table-auto text-gray-500 dark:text-gray-400 w-full border-collapse">
              <thead className="sticky top-0 text-xs text-white uppercase bg-gray-600 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 py-5 flex-1 border-r"
                  >
                    No
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 flex-1 border-r"
                  >
                    Product ID
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 flex-1 border-r"
                  >
                    Product Name
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 flex-1 border-r"
                  >
                    QTY
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 flex-1 border-r"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 flex-1 border-r"
                  >
                    Remark
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 flex-1 border-r"
                  >
                    Customer
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 flex-1 border-r"
                  >
                    Input By
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-5 flex-1"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {tempArray.map((data, index) => (
                  <tr
                    className={`py-6 text-xs border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${applyStripedStyle(
                      index
                    )}`}
                    key={data.no}
                  >
                    <td className="whitespace-nowrap px-5 py-5 flex-1 border-r">
                      {data.no}
                    </td>
                    <td className="whitespace-nowrap px-5 flex-1 border-r">
                      {data.product_id}
                    </td>
                    <td className="whitespace-nowrap px-5 flex-1 border-r">
                      {data.product_name}
                    </td>
                    <td className="whitespace-nowrap px-5 flex-1 border-r">
                      {data.temp_qty}
                    </td>
                    <td
                      className={`whitespace-nowrap font-bold px-5 ${
                        data.transaction_type === "Stock In"
                          ? "bg-green-500 text-white"
                          : "bg-yellow-200"
                      } flex-1 border-r`}
                    >
                      {data.transaction_type}
                    </td>
                    <td className="whitespace-nowrap px-5 flex-1 border-r">
                      {data.transaction_remark}
                    </td>
                    <td className="whitespace-nowrap px-5 flex-1 border-r">
                      {data.transaction_for}
                    </td>
                    <td className="whitespace-nowrap px-5 flex-1 border-r">
                      {data.transaction_by}
                    </td>
                    <td className="whitespace-nowrap px-2 py-3">
                      <button
                        className="font-medium text-red-500 dark:text-red-500 hover:underline px-3"
                        onClick={(e) => {
                          e.preventDefault();
                          deleteObject(data.no);
                        }}
                      >
                        <TbTrashX size={"1.5em"} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {tempArray.length > 0 && (
              <Button className="my-5" fullSized type="submit">
                Insert Data
              </Button>
            )}
          </div>
        </form>
      </div>
    </>
  );
}

export default StockInOut;
