import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getOperator } from "../features/OperatorSlice";

function Operators({ onChangeOpr, oprData }) {
  const [opr, setOpr] = useState([]);
  const dispatch = useDispatch();
  const { operator } = useSelector((state) => state.operator);

  useEffect(() => {
    if (!operator) {
      dispatch(getOperator());
    } else {
      setOpr(operator.result);
    }
  }, [dispatch, operator]);

  return (
    <select
      className="block appearance-none w-full  border border-green-400 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      id="grid-operator"
      value={oprData}
      onChange={onChangeOpr}
    >
      {Array.isArray(opr) ? (
        opr.map((order, index) => (
          <option value={order.opr_id} key={`opr-${index}`}>
            {order.opr_name}
          </option>
        ))
      ) : (
        <option value="" key="empty-option">
          Loading... {/* or any other placeholder */}
        </option>
      )}
    </select>
  );
}

export default Operators;
