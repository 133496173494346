import React, { useState } from "react";
import axios from "axios";
import { Button } from "flowbite-react";
import ClientList from "./ClientList";

const JobsheetList = () => {
  const [jobs, setJobs] = useState([]);
  const [rows, setRows] = useState(0);
  const [desc, setDesc] = useState("");
  const [selectedClientId, setSelectedClientId] = useState(null);

  const handleClientChange = (clientId) => {
    setSelectedClientId(clientId);
    setDesc("");
    console.log(selectedClientId);
  };

  function applyStripedStyle(index) {
    return index % 2 === 0 ? "border-b bg-gray-50" : " bg-white"; // Apply the striped-row class to even-indexed rows
  }

  const searchbyCustomer = (e) => {
    e.preventDefault();
    getQuotesById(selectedClientId);
  };

  const handleDescriptionInput = (e) => {
    setDesc(e.target.value);
    console.log(desc);
  };

  const getQuotesById = async (cust_id) => {
    const response = await axios.get(
      `https://eps.elkomprintama.com/get/api/jobsheetlist/${cust_id}?search=${desc}`
    );
    setJobs(response.data.result);
    setRows(response.data.totalRows[0].totalRows);
  };

  const handleInitialClientId = (data) => {
    setSelectedClientId(data);
  };

  return (
    <>
      <div className=" flex flex-col h-full bg-gray-200 px-5 py-5 sm:rounded-lg">
        <form onSubmit={searchbyCustomer} className="mb-2">
          <div className="flex flex-wrap -mx-3 mb-5">
            <div className="w-full md:w-1/2 px-3">
              <ClientList
                onChangeList={handleClientChange}
                onInitial={handleInitialClientId}
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                htmlFor="search_panel"
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              >
                Search
              </label>
              <div className="control w-full  ">
                <input
                  className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id="search_panel"
                  name="search_panel"
                  type="text"
                  placeholder="Search Description"
                  value={desc}
                  onChange={handleDescriptionInput}
                />
              </div>
            </div>
          </div>

          <Button fullSized type="submit">
            Search Jobsheet
          </Button>
        </form>

        <p className="text-gray-900">Total Rows: {rows}</p>
        <div className="overflow-auto shadow-md sm:rounded-lg h-[70vh]">
          <table className="text-sm text-left text-gray-500 dark:text-gray-400 border-collapse">
            <thead className="sticky top-0 text-xs text-white uppercase bg-gray-600 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 py-5 border-r"
                >
                  Job ID
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Input Date
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Customer
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Description
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Material
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Material Colour
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Size
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Printing
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  P/N
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  MPN
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Drawing
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Diecut
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Roll
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Core
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Flow
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Sheet
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Computer Label
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Packing
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Peforation
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Required
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Lamination
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Note
                </th>
                <th scope="col" className="whitespace-nowrap px-5 border-r">
                  Admin
                </th>
              </tr>
            </thead>
            <tbody>
              {jobs.map((job, index) => (
                <tr
                  className={`py-6 text-xs border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-red-50 dark:hover:bg-gray-600 ${applyStripedStyle(
                    index
                  )}`}
                  key={job.job_id}
                >
                  <td className="whitespace-nowrap px-5 py-5 border-r">
                    {job.job_id}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {new Date(job.job_date).toLocaleDateString()}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">{job.nme}</td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {job.job_name}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {job.mat_name}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {job.mat_clr}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {job.size}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">{job.ptg}</td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {job.pn_cust}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {job.mpn_elk}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {job.drw_no}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {job.die_no}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {job.roll}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {job.core}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">{job.flw}</td>
                  <td className="whitespace-nowrap px-5 border-r">{job.sht}</td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {job.cmp_label}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {job.std_pack}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {job.pefo}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {job.required}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {job.lami}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {job.note}
                  </td>
                  <td className="whitespace-nowrap px-5 border-r">
                    {job.admin}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div> </div>
      </div>
    </>
  );
};

export default JobsheetList;
