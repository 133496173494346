import React, { useState, useEffect } from "react";

import { Button } from "flowbite-react";
import { BsSearch } from "react-icons/bs";

import axios from "axios";

function NewDiecut() {
  const [unit, setUnit] = useState("");
  const [radius, setRadius] = useState("");
  const listUnit = ["mm", "inch"];
  const listRadius = [
    "-",
    "BO",
    "R0.5",
    "R1",
    "R2",
    "R2.5",
    "R3",
    "R3.5",
    "R4",
    "R4.5",
    "R5",
    "R6",
  ];

  const listModel = ["DIAMETER", "OVAL", "INNER", "ARROW", "SPECIAL", "STAR"];
  const [model, setModel] = useState("");
  const [diecut_ups, SetDiecut_ups] = useState("");
  const [diecutLength, setDiecutLength] = useState("");
  const [viewType, setViewType] = useState("0");
  const [diecutWidth, setDiecutWidth] = useState("");

  const insertNormalDiecut = async (e) => {
    try {
      const response = await axios.post(
        `https://eps.elkomprintama.com/post/api/diecut/normal`,
        {
          die_p: diecutLength,
          die_l: diecutWidth,
          die_unit: unit,
          die_r: radius,
          die_ups: diecut_ups,
        }
      );
      if (response.status === 200) {
        // Access the 'msg' property from the response data
        alert(response.data.msg); // This will display the success message
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.msg);
      }
    }
  };

  const insertSpecDiecut = async (e) => {
    try {
      const response = await axios.post(
        `https://eps.elkomprintama.com/post/api/diecut/special`,
        {
          spec_model: model,
          spec_p: diecutLength,
          spec_l: diecutWidth,
          spec_unit: unit,
          spec_ups: diecut_ups,
        }
      );
      if (response.status === 200) {
        // Access the 'msg' property from the response data
        alert(response.data.msg); // This will display the success message
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.msg);
      }
    }
  };

  const insertDiecut = (e) => {
    e.preventDefault();

    switch (viewType) {
      case "0":
        if (!unit || !radius || !diecutLength || !diecutWidth || !diecut_ups) {
          alert("Please fill in all fields");
          return;
        }
        const confirmation = window.confirm(
          "Are you sure you want to insert the data?"
        );

        if (confirmation) {
          insertNormalDiecut();
        }
        break;
      case "1":
        if (!model || !unit || !diecutLength || !diecut_ups) {
          alert("Please fill in all fields");
          return;
        }

        const confirmations = window.confirm(
          "Are you sure you want to insert the data?"
        );

        if (confirmations) {
          insertSpecDiecut();
        }
    }
  };

  const handleViewTypeChange = (e) => {
    setViewType(e.target.value);
    setUnit("");
    setRadius("");
    setDiecutLength("");
    setDiecutWidth("");
  };

  return (
    <>
      <div className=" h-full py-2 px-5 bg-gray-100  sm:rounded-lg">
        <form onSubmit={insertDiecut}>
          <div className="control w-full  ">
            <div className="w-full">
              <label
                className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                htmlFor="show-option"
              >
                Insert Diecut
              </label>
              <select
                className={`block appearance-none w-full border  border-green-400"
              text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="show-option"
                name="show-option"
                value={viewType}
                onChange={handleViewTypeChange}
              >
                <option value={0} key="Viewoption0">
                  Normal Diecut
                </option>
                <option value={1} key="Viewoption1">
                  Special Diecut
                </option>
              </select>
            </div>
          </div>
          {viewType === "0" ? (
            <div className="field w-full my-2">
              <div className="control w-full  ">
                <div className="flex flex-wrap -mx-3 ">
                  <div className="w-full flex-1 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="ndiecut_length"
                    >
                      Length
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="ndiecut_length"
                      type="text"
                      placeholder="Length"
                      value={diecutLength}
                      onChange={(e) => {
                        setDiecutLength(e.target.value);
                      }}
                    />
                  </div>

                  <div className="w-full  flex-1 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="ndiecut_width"
                    >
                      Width
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="ndiecut_width"
                      type="text"
                      placeholder="Width"
                      value={diecutWidth}
                      onChange={(e) => {
                        setDiecutWidth(e.target.value);
                      }}
                    />
                  </div>
                  <div className="w-full  flex-1 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="diecut_unit"
                    >
                      Unit
                    </label>
                    <select
                      className="block appearance-none w-full border border-green-400 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="diecut_unit"
                      name="diecut_unit"
                      onChange={(e) => {
                        setUnit(e.target.value);
                      }}
                    >
                      <option value={0} key={`default-0`}>
                        Please select
                      </option>
                      {Array.isArray(listUnit) ? (
                        listUnit.map((data, index) => (
                          <option value={data} key={`unit-${index}`}>
                            {data}
                          </option>
                        ))
                      ) : (
                        <option value="" key="empty-option">
                          Loading... {/* or any other placeholder */}
                        </option>
                      )}
                    </select>
                  </div>
                  <div className="w-full  flex-1 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="diecut_radius"
                    >
                      Radius
                    </label>
                    <select
                      className="block appearance-none w-full border border-green-400 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="diecut_radius"
                      name="diecut_radius"
                      onChange={(e) => {
                        setRadius(e.target.value);
                      }}
                    >
                      <option value={0} key={`default-0`}>
                        Please select
                      </option>
                      {Array.isArray(listRadius) ? (
                        listRadius.map((data, index) => (
                          <option value={data} key={`unit-${index}`}>
                            {data}
                          </option>
                        ))
                      ) : (
                        <option value="" key="empty-option">
                          Loading... {/* or any other placeholder */}
                        </option>
                      )}
                    </select>
                  </div>
                  <div className="w-full  flex-1 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="ndiecut_width"
                    >
                      Ups
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="ndiecut_width"
                      type="text"
                      placeholder="Ups"
                      value={diecut_ups}
                      onChange={(e) => {
                        SetDiecut_ups(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="field w-full my-2">
              <div className="control w-full  ">
                <div className="flex flex-wrap -mx-3 ">
                  <div className="w-full flex-1 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="diecut_model"
                    >
                      Model
                    </label>
                    <select
                      className="block appearance-none w-full border border-green-400 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="diecut_model"
                      name="diecut_model"
                      onChange={(e) => {
                        setModel(e.target.value);
                        if (e.target.value === "DIAMETER") {
                          setDiecutWidth("0");
                        } else {
                          setDiecutLength("");
                          setDiecutWidth("");
                          SetDiecut_ups("");
                        }
                      }}
                    >
                      <option value={0} key={`default-0`}>
                        Please select
                      </option>
                      {Array.isArray(listModel) ? (
                        listModel.map((data, index) => (
                          <option value={data} key={`unit-${index}`}>
                            {data}
                          </option>
                        ))
                      ) : (
                        <option value="" key="empty-option">
                          Loading... {/* or any other placeholder */}
                        </option>
                      )}
                    </select>
                  </div>

                  <div className="w-full  flex-1 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="ndiecut_length"
                    >
                      {`${model === "DIAMETER" ? "Diameter" : "Length"}`}
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="ndiecut_length"
                      type="text"
                      placeholder="Length"
                      value={diecutLength}
                      onChange={(e) => {
                        setDiecutLength(e.target.value);
                      }}
                    />
                  </div>
                  {model !== "DIAMETER" && (
                    <div className="w-full  flex-1 px-3">
                      <label
                        className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                        htmlFor="ndiecut_width"
                      >
                        Width
                      </label>
                      <input
                        className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="ndiecut_width"
                        type="text"
                        placeholder="Width"
                        value={diecutWidth}
                        onChange={(e) => {
                          setDiecutWidth(e.target.value);
                        }}
                      />
                    </div>
                  )}

                  <div className="w-full  flex-1 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="diecut_unit"
                    >
                      Unit
                    </label>
                    <select
                      className="block appearance-none w-full border border-green-400 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="diecut_unit"
                      name="diecut_unit"
                      onChange={(e) => {
                        setUnit(e.target.value);
                      }}
                    >
                      <option value={0} key={`default-0`}>
                        Please select
                      </option>
                      {Array.isArray(listUnit) ? (
                        listUnit.map((data, index) => (
                          <option value={data} key={`unit-${index}`}>
                            {data}
                          </option>
                        ))
                      ) : (
                        <option value="" key="empty-option">
                          Loading... {/* or any other placeholder */}
                        </option>
                      )}
                    </select>
                  </div>
                  <div className="w-full  flex-1 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="ndiecut_width"
                    >
                      Ups
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="ndiecut_width"
                      type="text"
                      placeholder="Ups"
                      value={diecut_ups}
                      onChange={(e) => {
                        SetDiecut_ups(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="my-3">
            <Button className="" fullSized type="submit">
              Insert
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default NewDiecut;
