import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";

function ClientList({ onChangeList, onInitial, axValue, axLabel }) {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    const fetchClientList = async () => {
      try {
        const response = await axios.get(
          "https://eps.elkomprintama.com/get/api/clients"
        );
        const clientData = response.data.result;

        setClients(
          clientData.map((client) => ({
            value: client.cust_id,
            label: client.nme,
          }))
        );

        if (clientData.length > 0) {
          setSelectedClient({
            value: axValue || clientData[0].cust_id,
            label: axLabel || clientData[0].nme,
          });
        }
      } catch (error) {
        console.error("Error fetching clients data:", error);
      }
    };

    fetchClientList();
  }, [axLabel, axValue]);

  const handleClientChange = (selectedOption) => {
    onChangeList(selectedOption.value);
    setSelectedClient(selectedOption);
  };

  return (
    <>
      <label
        htmlFor="client_list"
        className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
      >
        Customer
      </label>
      <div className="control w-full">
        <Select
          options={clients}
          className="block w-full border border-green-400 text-gray-700  rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="client_list"
          name="client_list"
          onChange={handleClientChange}
          value={selectedClient}
          styles={{
            control: (provided, state) => ({
              ...provided,
              border: state.isFocused
                ? "2px solid #4CAF50"
                : "1px solid #ced4da",
              boxShadow: "none",
            }),
          }}
        />
      </div>
    </>
  );
}

export default ClientList;
