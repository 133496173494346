import React, { useEffect } from "react";
import Layout from "./Layout";
import Documents from "../components/Documents.jsx";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "../features/authSlice.js";

function Msds() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError, user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
    // Adjust the conditional redirection logic based on user credentials
    // In this case, if the user doesn't have necessary access rights, redirect to dashboard
  }, [isError, navigate]);

  return (
    <>
      {user && (
        <Layout>
          <Documents docType={"msds"} />
        </Layout>
      )}
      {/* Add loading logic or handle unauthorized access */}
    </>
  );
}

export default Msds;
