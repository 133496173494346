import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./components/Login";
import Quotations from "./pages/Quotations";
import AddQuotation from "./pages/AddQuotation";
import Orders from "./pages/Orders";
import AddOrders from "./pages/AddOrder";
import Jobsheets from "./pages/Jobsheets";
import AddStoreGroup from "./pages/AddStoreGroup";
import AddMatLocation from "./pages/AddMatLocation";
import AddMaterial from "./pages/AddMaterial";
import Material from "./pages/Material";
import StockInPage from "./pages/StockInPage";
import StockOutPage from "./pages/StockOutPage";
import CurrentStock from "./pages/CurrentStock";
import Transactions from "./pages/Transactions";
import UserUpdates from "./pages/UserUpdates";
import Users from "./pages/Users";
import Msds from "./pages/Msds";
import Icp from "./pages/Icp";
import Specs from "./pages/Specs";
import Reports from "./pages/Reports";
import DiecutList from "./pages/DiecutList";
import AddDiecut from "./pages/AddDiecut";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/quotation" element={<Quotations />} />
          <Route path="/quotation/add" element={<AddQuotation />} />
          <Route path="/orderlist" element={<Orders />} />
          <Route path="/addOrder" element={<AddOrders />} />
          <Route path="/addstoregroup" element={<AddStoreGroup />} />
          <Route path="/addlocation" element={<AddMatLocation />} />
          <Route path="/jobsheet" element={<Jobsheets />} />
          <Route path="/addmaterial" element={<AddMaterial />} />
          <Route path="/materials" element={<Material />} />
          <Route path="/stockin" element={<StockInPage />} />
          <Route path="/stockout" element={<StockOutPage />} />
          <Route path="/currentstock" element={<CurrentStock />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/users" element={<Users />} />
          <Route path="/msds" element={<Msds />} />
          <Route path="/icp" element={<Icp />} />
          <Route path="/users/update" element={<UserUpdates />} />
          <Route path="/specs" element={<Specs />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/diecut-list" element={<DiecutList />} />
          <Route path="/add_diecut" element={<AddDiecut />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
