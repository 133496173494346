import React, { useState, useEffect } from "react";
import { Button } from "flowbite-react";
import axios from "axios";
import MaterialGroupSelect from "./MaterialGroupSelect";
import MaterialLocationSelect from "./MaterialLocationSelect";

function MaterialListModal({ dataSet, onClose, refreshData }) {
  const listUnit = ["ROLL", "SHEET", "PLY", "KG"];

  const [selectedMatGroup, setSelectedMatGroup] = useState(null);
  const [productName, setProductName] = useState("");
  const [productCode, setProductCode] = useState("");
  const [productUnit, setProductUnit] = useState(listUnit[0]);
  const [productLocation, setProductLocation] = useState("");

  useEffect(() => {
    if (dataSet) {
      setProductName(dataSet.product_name);
      setProductCode(dataSet.product_code);
      setProductUnit(dataSet.unit);
    }
  }, [dataSet]);

  const handleProductUnitChange = (e) => {
    setProductUnit(e.target.value);
  };
  const handleProductLocationChange = (locationId) => {
    setProductLocation(locationId);
  };

  const handleProductNameChange = (e) => {
    setProductName(e.target.value);
  };

  const handleProductCodeChange = (e) => {
    setProductCode(e.target.value);
  };

  const handleMatGroupChange = (GroupId) => {
    setSelectedMatGroup(GroupId);
  };

  const updateOrder = async (e) => {
    try {
      const response = await axios.patch(
        `https://eps.elkomprintama.com/patch/api/store/store/mats`,
        {
          productCode: productCode.toUpperCase(),
          productName: productName.toUpperCase(),
          groupId: selectedMatGroup,
          unit: productUnit,
          productLocationId: productLocation,
          productId: dataSet.product_id,
        }
      );
      if (response.status === 200) {
        // Access the 'msg' property from the response data
        alert(response.data.msg); // This will display the success message
        setProductName("");
        setProductCode("");
        onClose();
        refreshData();
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.msg);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!productName || !productCode || !selectedMatGroup) {
      window.alert("Please fill in all required fields");
      return;
    }
    const confirmation = window.confirm(
      "Are you sure you want to insert the data?"
    );

    if (confirmation) {
      updateOrder();
    }
  };

  return (
    <div className="relative max-w-2xl w-full  bg-gray-100  rounded-lg">
      <form className="my-5" onSubmit={handleSubmit}>
        <MaterialLocationSelect
          dataSet={dataSet}
          onChangeList={handleProductLocationChange}
        ></MaterialLocationSelect>
        <div className="field w-full my-2">
          <div className="control w-full  ">
            <div className="flex flex-wrap -mx-3 mb-5">
              <div className="w-full md:w-4/5 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="product_name"
                >
                  Product Name
                </label>
                <input
                  className="appearance-none uppercase block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id="product_name"
                  name="product_name"
                  type="text"
                  placeholder="Product Name"
                  value={productName}
                  onChange={handleProductNameChange}
                />
              </div>
              <div className="w-full md:w-1/5 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="product_code"
                >
                  Product Code
                </label>
                <input
                  className="uppercase appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id="product_code"
                  name="product_code"
                  type="text"
                  placeholder="Product Code"
                  value={productCode}
                  onChange={handleProductCodeChange}
                />
              </div>
            </div>
          </div>
        </div>
        <MaterialGroupSelect
          dataSet={dataSet}
          onChangeList={handleMatGroupChange}
        />
        <div className="w-full mb-5 ">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="product_unit"
          >
            UNIT
          </label>
          <select
            className="block appearance-none w-full border border-green-400 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="product_unit"
            name="product_unit"
            onChange={handleProductUnitChange}
            value={productUnit}
          >
            {Array.isArray(listUnit) ? (
              listUnit.map((data, index) => (
                <option value={data} key={`listU-${index}`}>
                  {data}
                </option>
              ))
            ) : (
              <option value="" key="empty-option">
                Loading... {/* or any other placeholder */}
              </option>
            )}
          </select>
        </div>
        <div className="w-full ">
          <Button fullSized color="blue" type="submit">
            UPDATE
          </Button>
        </div>
      </form>
    </div>
  );
}

export default MaterialListModal;
