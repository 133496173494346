import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  operator: null,
  message: "",
};

export const getOperator = createAsyncThunk(
  "user/getOperator",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `https://eps.elkomprintama.com/get/api/opr`
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const oprSlice = createSlice({
  name: "operator",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOperator.fulfilled, (state, action) => {
      state.operator = action.payload;
    });
    builder.addCase(getOperator.rejected, (state, action) => {
      state.message = action.payload;
    });
  },
});

export default oprSlice.reducer;
