import React, { useState, useEffect } from "react";
import { Button } from "flowbite-react";
import axios from "axios";
import DocumentModal from "./DocumentsModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

function Documents({ docType }) {
  const [fileName, setFileName] = useState("");
  const [msdsList, setMsdsList] = useState([]);
  const [file, setFile] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [inputDateSelected, setInputDateSelected] = useState(new Date());

  useEffect(() => {
    getDocsList();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      alert("Please select a file.");
      return;
    } else if (!fileName) {
      alert("Please input name");
      return;
    }

    const confirmation = window.confirm(
      "Are you sure you want to insert the data?"
    );

    if (confirmation) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        // Extract the file extension from the original filename
        const fileExtension = file.name.split(".").pop();

        // Append custom filename with the file extension
        formData.append("customFileName", fileName + "." + fileExtension);
        formData.append("issue_date", format(inputDateSelected, "yyyy-MM-dd"));

        formData.forEach((value, key) => {
          console.log(key, value);
        });

        const response = await axios.post(
          `https://eps.elkomprintama.com/${docType}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        alert("File uploaded successfully:", response.data);
        getDocsList();
        setFileName("");
        document.getElementById("file_input").value = "";
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const getDocsList = async () => {
    try {
      const response = await axios.get(
        `https://eps.elkomprintama.com/get/api/${docType}`
      );
      setMsdsList(response.data.result);
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  const isExpired = (issueDate, docType) => {
    const today = new Date();
    const issue = new Date(issueDate);

    let expirationPeriod;

    if (docType === "msds") {
      expirationPeriod = 5 * 365 * 24 * 60 * 60 * 1000; // 5 years in milliseconds
    } else if (docType === "icp") {
      expirationPeriod = 1 * 365 * 24 * 60 * 60 * 1000; // 1 year in milliseconds
    } else {
      return "n/a";
    }

    return today - issue > expirationPeriod;
  };

  const downloaDocs = async (customPath, originalName) => {
    try {
      const response = await axios.get(
        `https://eps.elkomprintama.com/get/api/docs/download`,
        {
          params: {
            customPath: customPath,
          },
          responseType: "blob", // Set the response type to blob
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data])); // Create a URL to the blob content
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", originalName); // Set the desired file name here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };

  function applyStripedStyle(index) {
    return index % 2 === 0 ? "border-b bg-gray-50" : " bg-white"; // Apply the striped-row class to even-indexed rows
  }

  const handleDownload = (filePath, fileName) => {
    downloaDocs(filePath, fileName);
  };

  return (
    <>
      <div className={`w-full  rounded-lg `}>
        <label
          className={`block uppercase text-white  mb-2 tracking-wide  text-5xl text-center  font-bold `}
        >
          {docType}
        </label>
      </div>
      <div className="flex flex-col  bg-gray-200 px-5 py-5 sm:rounded-lg">
        <form className="my-5" onSubmit={handleUpload}>
          <div className="field w-full my-2">
            <div className="flex flex-wrap -mx-3 mb-5">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                  htmlFor={`grid-${docType}-filename`}
                >
                  Material Name
                </label>
                <input
                  className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id={`grid-${docType}-filename`}
                  type="text"
                  placeholder="Material"
                  onChange={handleFileNameChange}
                  value={fileName}
                />
              </div>
            </div>
          </div>
          <div className="field w-full ">
            <div className="flex flex-wrap -mx-3 mb-5">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                  htmlFor={`grid-${docType}-filename`}
                >
                  Choose a file
                </label>
                <input
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  id="file_input"
                  onChange={handleFileChange}
                  type="file"
                ></input>
              </div>
            </div>
          </div>
          <div className="field w-full ">
            <div className="flex flex-wrap -mx-3 mb-5">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                  htmlFor={`grid-${docType}-issuedate`}
                >
                  Issue Date
                </label>
                <DatePicker
                  name={`input-date`}
                  id={`input-date`}
                  className={`block appearance-none w-full border  border-green-400
                  text-gray-800 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                  wrapperClassName={"w-full  "}
                  selected={inputDateSelected}
                  onChange={(e) => {
                    setInputDateSelected(e);
                  }}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
          </div>
          <div className="w-full ">
            <Button fullSized type="submit">
              Insert
            </Button>
          </div>
        </form>
        <div className="overflow-auto shadow-md sm:rounded-lg h-[70vh]">
          <table className="text-sm text-left table-auto text-gray-500 dark:text-gray-400 w-full border-collapse">
            <thead className="sticky top-0 text-xs text-white uppercase bg-gray-600 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 py-5 flex-1 border-r"
                >
                  Upload id
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Material Name
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Size
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Issue Date
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Expiration Status
                </th>
                <th scope="col" className="whitespace-nowrap px-5 flex-1">
                  Download
                </th>
              </tr>
            </thead>
            <tbody>
              {msdsList.map((data, index) => (
                <tr
                  onDoubleClick={() => {
                    setShowModal(!showModal);
                    setModalData(data);
                  }}
                  className={`py-6 text-xs border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${applyStripedStyle(
                    index
                  )}`}
                  key={index}
                >
                  <td className="whitespace-nowrap px-5 py-5 flex-1 border-r">
                    {data.id}
                  </td>
                  <td className="whitespace-nowrap px-5 flex-1 border-r">
                    {data.filename}
                  </td>
                  <td className="whitespace-nowrap px-5 flex-1 border-r">
                    {(data.size / 1000).toFixed(2) + " Kb"}
                  </td>
                  <td className="whitespace-nowrap px-5 flex-1 border-r">
                    {new Date(data.issue_date).toLocaleDateString()}
                  </td>
                  <td
                    className={`whitespace-nowrap px-5 flex-1 border-r ${
                      isExpired(data.issue_date, docType) === true
                        ? "text-red-500 font-bold"
                        : ""
                    }`}
                  >
                    {isExpired(data.issue_date, docType) === "n/a"
                      ? "n/a"
                      : isExpired(data.issue_date, docType)
                      ? "Expired"
                      : "Valid"}
                  </td>
                  <td className="whitespace-nowrap px-5 flex-1">
                    <button
                      className="text-green-600"
                      onClick={() => handleDownload(data.path, data.filename)}
                    >
                      Download
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showModal ? (
        <div
          className={`fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex flex-col justify-center items-center`}
          // id="wrapper"
          // onClick={handleClose}
        >
          <div className="w-full max-w-2xl text-end ">
            <button
              className="text-gray-700 pr-1 pb-1 font-bold text-xl"
              onClick={() => {
                setShowModal(!showModal);
              }}
            >
              X
            </button>
          </div>
          <DocumentModal
            onClose={() => {
              setShowModal(false);
            }}
            dataSet={modalData}
            refreshData={() => {
              getDocsList();
            }}
          ></DocumentModal>
          {/* <MaterialListModal
          onClose={() => {
            setShowModal(false);
          }}
          dataSet={modalData}
          refreshData={() => {
            switch (viewType) {
              case "0":
                getallMaterialList();
                break;
              case "1":
                if (!productLocation) {
                  alert("Please select Location");
                  break;
                }

                getMaterialListById(selectedMatList);
                break;
              case "2":
                if (!selectedMatGroup) {
                  alert("Please select a group");
                  break;
                } else {
                  getGroupedMaterialList(selectedMatGroup);
                }

                break;
              default:
                getallMaterialList();
            }
          }}
        ></MaterialListModal> */}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Documents;
