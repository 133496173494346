import React, { useState } from "react";
import { Button } from "flowbite-react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import htmlToExcel from "html-table-to-excel";

function TransactionExport() {
  const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };
  const [selectedMonth, setSelectedMonth] = useState("");
  const [records, setRecords] = useState([]);
  const [yearSelected, setYearSelected] = useState(new Date());

  const exportToExcel = () => {
    // Specify the table's ID or class
    const table = document.getElementById("myTable");

    // Provide a file name for the exported Excel file
    const fileName = "exported_table.xlsx";

    // Export the table to Excel
    htmlToExcel(table, fileName);
  };

  const formatRecord = (data) => {
    if (!data || data.length === 0) return {};

    const groupedData = data.reduce((acc, currentItem) => {
      const { group_name } = currentItem;
      if (!acc[group_name]) {
        acc[group_name] = [];
      }
      acc[group_name].push(currentItem);
      return acc;
    }, {});

    return groupedData;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedMonth) {
      alert("Please choose month");
      return;
    } else {
      getMonthlyRecord();
    }
  };

  const handleYearSelected = (date) => {
    setYearSelected(date);
  };

  const getMonthlyRecord = async () => {
    const response = await axios.get(
      `https://eps.elkomprintama.com/get/api/store/transaction/report?month=${selectedMonth}&year=${yearSelected.getFullYear()}`
    );

    const formattedData = formatRecord(response.data.result);
    setRecords(formattedData);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  function applyStripedStyle(index) {
    return index % 2 === 0 ? "border-b bg-gray-50" : " bg-white"; // Apply the striped-row class to even-indexed rows
  }

  return (
    <>
      <div className="h-full">
        <div className="relative  bg-gray-100 overflow-x-auto  px-5 py-4 mb-5 sm:rounded-lg">
          <div className="control w-full flex flex-wrap ">
            <div className="w-full md:w-1/6">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="show-option"
              >
                Year
              </label>
              <DatePicker
                className={`block appearance-none w-full border  border-green-400"
              text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                selected={yearSelected}
                onChange={handleYearSelected}
                renderYearContent={renderYearContent}
                showYearPicker
                dateFormat="yyyy"
              />
            </div>
            <div className="w-full md:w-5/6 ">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="show-option"
              >
                Month
              </label>

              <select
                className={`block appearance-none w-full border  border-green-400"
              text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="show-option"
                value={selectedMonth}
                onChange={handleMonthChange}
              >
                <option value="">Select Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </div>
          </div>
          <div>
            <div className="field w-full my-2">
              <div className="control w-full  "></div>
            </div>
            <form onSubmit={handleSubmit}>
              <Button className="mt-5" fullSized type="submit">
                Check Records
              </Button>
            </form>
          </div>
        </div>
        <div className="relative overflow-x-auto  shadow-md sm:rounded-lg">
          <table
            id="myTable"
            className="text-sm text-left table-auto text-gray-500 dark:text-gray-400 w-full border-collapse"
          >
            <thead className="sticky border-gray-300 top-0 text-xs text-white uppercase bg-gray-600 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 py-5 flex-1 border-r"
                >
                  No
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Product CODE
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-5 flex-1 border-r"
                >
                  Product Name
                </th>
                <th scope="col" className="whitespace-nowrap px-5 flex-1">
                  Stock QTY
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(records).map(
                ([groupName, groupItems], groupIndex) => (
                  <React.Fragment key={`Group_e_${groupIndex}`}>
                    <tr className="bg-gray-200 ">
                      <td
                        className={`whitespace-nowrap px-5 py-5  border-gray-300 border-y-2`}
                      ></td>

                      <td
                        className="border-y-2 px-5  text-gray-400  border-gray-300 py-2  font-bold"
                        colSpan={3}
                      >
                        {groupName}
                      </td>
                    </tr>
                    {groupItems.map((data, index) => (
                      <tr
                        className={`border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-red-50 dark:hover:bg-gray-600 ${applyStripedStyle(
                          index
                        )}`}
                        key={`${groupName}_${index}`}
                      >
                        <td className={`whitespace-nowrap px-5 py-5 border-r`}>
                          {index + 1}
                        </td>
                        <td className={`whitespace-nowrap px-5 border-r`}>
                          {data.product_code}
                        </td>
                        <td className="whitespace-nowrap px-5 border-r">
                          {data.product_name}
                        </td>
                        <td className="whitespace-nowrap px-5">{data.qty}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                )
              )}
            </tbody>
          </table>

          {Object.keys(records).length > 0 && (
            <Button className="my-5" fullSized onClick={exportToExcel}>
              Download
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default TransactionExport;
