import React from "react";
import Select from "react-select";

function JsList({ jobsheet, onChangeList, initialValue }) {
  const options = Array.isArray(jobsheet)
    ? jobsheet.map((order, index) => ({
        value: order.job_id,
        label: order.nme,
      }))
    : [];

  return (
    <>
      <label
        htmlFor="js_list"
        className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
      >
        Jobsheet
      </label>
      <div className="control w-full">
        <Select
          id="js_list"
          className="block w-full border border-green-400 text-gray-700  rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          name="js_list"
          options={options}
          onChange={onChangeList}
          value={initialValue}
        />
      </div>
    </>
  );
}

export default JsList;
