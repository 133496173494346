import React, { useEffect, useState } from "react";
import axios from "axios";

function MaterialGroupSelect({ onChangeList, dataSet }) {
  const [groupNameList, setGroupNameList] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState("");

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://eps.elkomprintama.com/get/api/store/group`
      );
      if (
        response.data &&
        response.data.result &&
        response.data.result.length > 0
      ) {
        setGroupNameList(response.data.result);

        if (dataSet && dataSet.group_id) {
          setSelectedGroupId(dataSet.group_id);
          onChangeList(dataSet.group_id);
        }
      } else {
        setGroupNameList([]);

        onChangeList(""); // You might adjust this based on your needs
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        alert(error.response.data.msg);
      } else {
        alert("An error occurred");
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run the effect only once

  const handleClientChange = (e) => {
    onChangeList(e.target.value);
    setSelectedGroupId(e.target.value);
  };

  return (
    <div className="field w-full my-2 mb-5">
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor="mat_group"
      >
        Material Group
      </label>
      <div className="control w-full  ">
        <select
          className="block appearance-none w-full border border-green-400 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="mat_group"
          name="mat_group"
          value={selectedGroupId}
          onChange={handleClientChange}
        >
          <option value="">Select Group</option>
          {Array.isArray(groupNameList) ? (
            groupNameList.map((data, index) => (
              <option value={data.group_id} key={`group-${index}`}>
                {data.group_name}
              </option>
            ))
          ) : (
            <option value="" key="empty-option">
              Loading...
            </option>
          )}
        </select>
      </div>
    </div>
  );
}

export default MaterialGroupSelect;
