import React, { useEffect } from "react";
import Layout from "./Layout.jsx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe, reset } from "../features/authSlice.js";
import NewDiecut from "../components/NewDiecut.jsx";

const AddDiecut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError, user, isSuccess } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);

  return (
    <Layout>
      <NewDiecut></NewDiecut>
    </Layout>
  );
};

export default AddDiecut;
