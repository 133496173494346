import React from "react";
import ClientList from "./ClientList";
import JsList from "./JsList";
import { useState, useEffect } from "react";
import axios from "axios";
import DateTimePicker from "./DateTimePicker";
import { format } from "date-fns";
import { Button } from "flowbite-react";

const NewOrder = () => {
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [selectedInitJobId, setSelectedInitJobId] = useState(null);
  const [jobsheet, setJobsheet] = useState([]);
  const [qtyOrder, setQtyOrder] = useState("");
  const [poOrder, setPoOrder] = useState("");
  const [inputDateSelected, setInputDateSelected] = useState(new Date());
  const [deliveryDateSelected, setDeliveryDateSelected] = useState(
    new Date(new Date().setDate(new Date().getDate() + 7))
  );

  const handleClientChange = (clientId) => {
    setSelectedClientId(clientId);
  };

  useEffect(() => {
    const fetchJobsheet = async () => {
      try {
        if (selectedClientId) {
          const response = await axios.get(
            `https://eps.elkomprintama.com/get/api/jobsheet/${selectedClientId}`
          );
          setJobsheet(response.data.result);
          setSelectedJobId(response.data.result[0]?.job_id || null);
          if (
            response.data &&
            response.data.result &&
            response.data.result.length > 0
          ) {
            setSelectedInitJobId({
              value: response.data.result[0].job_id,
              label: response.data.result[0].nme,
            });
          } else {
            setSelectedInitJobId(null);
          }
        }
      } catch (error) {
        console.error("Error fetching jobsheet data:", error);
      }
    };
    fetchJobsheet();
  }, [selectedClientId]);

  const insertOrder = async (e) => {
    try {
      await axios.post(`https://eps.elkomprintama.com/post/api/orders/`, {
        jobId: selectedJobId,
        custId: selectedClientId,
        orderDate: format(inputDateSelected, "yyyy-MM-dd"),
        orderQty: qtyOrder,
        poNum: poOrder,
        dlvrDate: format(deliveryDateSelected, "yyyy-MM-dd"),
        oprId: 1,
        statusId: 1,
        qcId: 1,
      });
      alert("Data Inserted");
    } catch (error) {
      if (error.response) {
        alert(error.response.data.msg);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedClientId || !selectedJobId || !qtyOrder || !poOrder) {
      window.alert("Please fill in all required fields");
      return;
    }
    const confirmation = window.confirm(
      "Are you sure you want to insert the data?"
    );

    if (confirmation) {
      insertOrder();
      setSelectedClientId(null);
      setJobsheet([]);
      setInputDateSelected("");
      setDeliveryDateSelected("");
    }
  };

  const handleInputDateChange = (date) => {
    setInputDateSelected(date);
  };

  const handleDeliveryDateChange = (date) => {
    if (date < inputDateSelected) {
      alert("Cannot smaller than 'from date'");
      return;
    }
    setDeliveryDateSelected(date);
  };

  const handleJsListChange = (selectedOption) => {
    setSelectedJobId(selectedOption.value);
    setSelectedInitJobId(selectedOption);
  };

  const handleQtyChange = (e) => {
    const uppercaseValue = e.target.value.toUpperCase();
    setQtyOrder(uppercaseValue);
  };

  const handlePoChange = (e) => {
    const uppercaseValue = e.target.value.toUpperCase();
    setPoOrder(uppercaseValue);
  };

  return (
    <>
      <div className={`w-full  rounded-lg `}>
        <label
          className={`block uppercase text-white  mb-2 tracking-wide  text-2xl text-center  font-bold `}
        >
          New Order
        </label>
      </div>
      <div className="relative h-full  bg-gray-100 overflow-x-auto shadow-md px-5 sm:rounded-lg">
        <form className="my-5" onSubmit={handleSubmit}>
          <div className="field w-full my-2">
            <ClientList onChangeList={handleClientChange} />
          </div>
          <div className="field w-full my-2">
            <div className="control w-full  ">
              <JsList
                onChangeList={handleJsListChange}
                jobsheet={jobsheet}
                initialValue={selectedInitJobId}
              />
            </div>
          </div>
          <div className="field w-full my-2">
            <div className="control w-full  ">
              <DateTimePicker
                firstDateValue={inputDateSelected}
                onChangeFirstDate={handleInputDateChange}
                secondDateValue={deliveryDateSelected}
                onChangeSecondDate={handleDeliveryDateChange}
                firstLabel={"Order Date"}
                secondLabel={"Delivery Date"}
              ></DateTimePicker>
            </div>
          </div>
          <div className="field w-full my-2">
            <div className="control w-full  ">
              <div className="flex flex-wrap -mx-3 mb-5">
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                    htmlFor="grid-size"
                  >
                    QTY
                  </label>
                  <input
                    className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    id="grid-size"
                    type="text"
                    placeholder="QTY"
                    onChange={handleQtyChange}
                    value={qtyOrder}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                    htmlFor="grid-pn"
                  >
                    PO
                  </label>
                  <input
                    className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    id="grid-pn"
                    type="text"
                    placeholder="PO"
                    onChange={handlePoChange}
                    value={poOrder}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full ">
            <Button fullSized type="submit">
              Insert
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default NewOrder;
