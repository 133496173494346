import { useDispatch, useSelector } from "react-redux";
import { getMe, LogOut } from "../features/authSlice";
import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";

function LoginStatus() {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [showLogout, setShowLogout] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  const handleLogout = () => {
    dispatch(LogOut());
    navigate("/");
    // Additional logic for clearing user data or redirecting can be added here
  };

  return (
    <div className="relative">
      <p className="text-white font-medium">
        Welcome, {user && user.username}
        <button
          onClick={() => setShowLogout(!showLogout)}
          className="ml-2 rounded-md"
        >
          &#9662;
        </button>
      </p>
      {showLogout && (
        <div
          className="absolute top-8 z-50 right-0 whitespace-nowrap bg-gray-800 p-2 rounded-md shadow flex flex-col items-start"
          onMouseLeave={() => setShowLogout(false)}
        >
          <button className="mb-2 ">
            <NavLink to={"/users/update"}>Change Password</NavLink>
          </button>
          <button onClick={handleLogout}>Logout</button>
        </div>
      )}
    </div>
  );
}

export default LoginStatus;
