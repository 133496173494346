import React from "react";
import ClientList from "./ClientList";
import { useState, useEffect } from "react";
import { format } from "date-fns";
import axios from "axios";
import { Button } from "flowbite-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";

const NewQuotation = () => {
  const { user } = useSelector((state) => state.auth);
  const listTitle = ["Mr", "Ms"];
  const [refNo, setRefNo] = useState("");
  const [quoteDesc, setQuoteDesc] = useState("");
  const [quotePn, setQuotePn] = useState("");
  const [quoteSize, setQuoteSize] = useState("");
  const [quoteMaterial, setQuoteMaterial] = useState("");
  const [quotePrinting, setQuotePrinting] = useState("");
  const [quotePrice1, setQuotePrice1] = useState("");
  const [quotePrice2, setQuotePrice2] = useState("");
  const [quotePrice3, setQuotePrice3] = useState("");
  const [quotePrice4, setQuotePrice4] = useState("");
  const [quotePrice5, setQuotePrice5] = useState("");
  const [quoteMpn, setQuoteMpn] = useState("");
  const [quoteCurrency, setQuoteCurrency] = useState("IDR");
  const [quoteLt, setQuoteLt] = useState("1 weeks");
  const [quoteCxl, setQuoteCxl] = useState("NCNR");
  const [quoteResch, setQuoteResch] = useState("NCNR");
  const [quoteDelTerm, setQuoteDelTerm] = useState("FOB BATAM");
  const [quotePayTerm, setQuotePayTerm] = useState("30 Days");
  const [quoteEffType, setQuoteEffType] = useState("PO BASED");
  const [quoteExpDate, setQuoteExpDate] = useState(
    "3 months after the date of quotation"
  );
  const [quoteOrigin, setQuoteOrigin] = useState("Indonesia");
  const [attn, setAttn] = useState("");
  const [title, setTitle] = useState("");
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [inputDateSelected, setInputDateSelected] = useState(new Date());

  const handleQuotePrintingChange = (e) => {
    setQuotePrinting(e.target.value);
  };

  const handleQuoteMaterialChange = (e) => {
    setQuoteMaterial(e.target.value);
  };

  const handleQuoteSizeChange = (e) => {
    setQuoteSize(e.target.value);
  };

  const handleQuotePnChange = (e) => {
    setQuotePn(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleAttnChange = (e) => {
    setAttn(e.target.value);
  };

  const handleQuoteDescChange = (e) => {
    setQuoteDesc(e.target.value);
  };

  const handleClientChange = (clientId) => {
    setSelectedClientId(clientId);
  };

  const fetchQuotationId = async () => {
    try {
      const response = await axios.get(
        `https://eps.elkomprintama.com/get/api/quotation`
      );

      setRefNo(response.data.result[0].max_quote_id + 1);
    } catch (error) {
      console.error("Error fetching jobsheet data:", error);
    }
  };

  useEffect(() => {
    fetchQuotationId();
  }, []);

  const insertQuotation = async (e) => {
    try {
      const response = await axios.post(
        `https://eps.elkomprintama.com/post/api/quotation`,
        {
          quote_id: refNo,
          quote_date: format(inputDateSelected, "yyyy-MM-dd"),
          cust_id: selectedClientId,
          attn: title + " " + attn,
          descr: quoteDesc,
          pn: quotePn,
          size: quoteSize,
          mat: quoteMaterial,
          clr: quotePrinting,
          price1: quotePrice1,
          price2: quotePrice2,
          price3: quotePrice3,
          price4: quotePrice4,
          price5: quotePrice5,
          curr: quoteCurrency,
          lead: quoteLt,
          term: quotePayTerm,
          effect_type: quoteEffType,
          mpn: quoteMpn,
          coo: quoteOrigin,
          admin: user.username,
        }
      );
      alert(response.data.msg);
      fetchQuotationId();
      setTitle("0");
      setAttn("");
      setQuoteDesc("");
      setQuotePn("");
      setQuoteSize("");
      setQuoteMaterial("");
      setQuotePrinting("");
      setQuotePrice1("");
      setQuotePrice2("");
      setQuotePrice3("");
      setQuotePrice4("");
      setQuotePrice5("");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      title === "0" ||
      !attn ||
      !quoteDesc ||
      !quotePn ||
      !quoteSize ||
      !quoteMaterial ||
      !quotePrinting ||
      !quotePrice1
    ) {
      window.alert("Please fill in all required fields");
      return;
    }
    const confirmation = window.confirm(
      "Are you sure you want to insert the data?"
    );

    if (confirmation) {
      insertQuotation();
    }
  };

  const handleInputDateChange = (date) => {
    setInputDateSelected(date);
  };

  const handleInitialClientId = (data) => {
    setSelectedClientId(data);
  };

  return (
    <>
      <div className={`w-full  rounded-lg `}>
        <label
          className={`block uppercase text-white  mb-2 tracking-wide  text-2xl text-center  font-bold `}
        >
          New Quotation
        </label>
      </div>
      <div className=" bg-gray-200 py-5 px-5 sm:rounded-lg">
        <form className="" onSubmit={handleSubmit}>
          {/* <!-- Quotation number -->  */}

          <div className="border-solid border-b-2 border-gray-300 p-5">
            <div className="field w-full ">
              <div className="control w-full  ">
                <div className="flex flex-wrap -mx-3 ">
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="our-ref"
                    >
                      Quotation number
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="our-ref"
                      type="text"
                      placeholder="Ref No"
                      value={refNo}
                      disabled
                      readOnly
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="input-date"
                    >
                      Date
                    </label>
                    <DatePicker
                      name={`input-date`}
                      id={`input-date`}
                      className={`block appearance-none w-full border  border-green-400
                  text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                      wrapperClassName={"w-full  "}
                      selected={inputDateSelected}
                      onChange={handleInputDateChange}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field w-full my-2">
              <ClientList
                onChangeList={handleClientChange}
                onInitial={handleInitialClientId}
              />
            </div>
            <div className="field w-full ">
              <div className="control w-full  ">
                <div className="flex flex-wrap -mx-3 ">
                  <div className="w-full md:w-1/6 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="our-ref"
                    >
                      Title
                    </label>
                    <select
                      className="block appearance-none w-full border border-green-400 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="product_unit"
                      name="product_unit"
                      onChange={handleTitleChange}
                    >
                      <option value={0} key={`default-0`}>
                        Please select
                      </option>
                      {Array.isArray(listTitle) ? (
                        listTitle.map((data, index) => (
                          <option value={data} key={`title-${index}`}>
                            {data}
                          </option>
                        ))
                      ) : (
                        <option value="" key="empty-option">
                          Loading... {/* or any other placeholder */}
                        </option>
                      )}
                    </select>
                  </div>
                  <div className="w-full md:w-5/6 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="input-date"
                    >
                      Attention
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="our-ref"
                      type="text"
                      placeholder="Name"
                      value={attn}
                      onChange={handleAttnChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Item Description -->  */}

          <div className="border-solid border-b-2 border-gray-300 p-5 ">
            <div className="field w-full ">
              <div className="control w-full  ">
                <div className="flex flex-wrap -mx-3 ">
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_desc"
                    >
                      Description
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_desc"
                      type="text"
                      placeholder="Description"
                      value={quoteDesc}
                      onChange={handleQuoteDescChange}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_pn"
                    >
                      Part Number
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_pn"
                      type="text"
                      placeholder="Part Number"
                      value={quotePn}
                      onChange={handleQuotePnChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field w-full my-2">
              <div className="control w-full  ">
                <div className="flex flex-wrap -mx-3 ">
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_size"
                    >
                      Size
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_size"
                      type="text"
                      placeholder="Size"
                      value={quoteSize}
                      onChange={handleQuoteSizeChange}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_material"
                    >
                      Material
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_material"
                      type="text"
                      placeholder="Material"
                      value={quoteMaterial}
                      onChange={handleQuoteMaterialChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field w-full my-2">
              <div className="control w-full  ">
                <div className="flex flex-wrap -mx-3 ">
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_printing"
                    >
                      Printing
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_printing"
                      type="text"
                      placeholder="Printing"
                      value={quotePrinting}
                      onChange={handleQuotePrintingChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Pricing-->  */}

          <div className="border-solid border-b-2 border-gray-300 p-5 ">
            <div className="field w-full ">
              <div className="control w-full  ">
                <div className="flex flex-wrap -mx-3 ">
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_pricing1"
                      onDoubleClick={() => {
                        setQuotePrice1("QTY.................@Rp");
                      }}
                    >
                      Pricing
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_pricing1"
                      type="text"
                      placeholder="Pricing 1"
                      value={quotePrice1}
                      onChange={(e) => {
                        setQuotePrice1(e.target.value);
                      }}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_pricing2"
                      onDoubleClick={() => {
                        setQuotePrice2("QTY.................@Rp");
                      }}
                    >
                      Pricing 2
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_pricing2"
                      type="text"
                      placeholder="Pricing 2"
                      value={quotePrice2}
                      onChange={(e) => {
                        setQuotePrice2(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field w-full my-2">
              <div className="control w-full  ">
                <div className="flex flex-wrap -mx-3 ">
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_pricing3"
                      onDoubleClick={() => {
                        setQuotePrice3("QTY.................@Rp");
                      }}
                    >
                      Pricing 3
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_pricing3"
                      type="text"
                      placeholder="Pricing 3"
                      value={quotePrice3}
                      onChange={(e) => {
                        setQuotePrice3(e.target.value);
                      }}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_pricing4"
                      onDoubleClick={() => {
                        setQuotePrice4("QTY.................@Rp");
                      }}
                    >
                      Pricing 4
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_pricing4"
                      type="text"
                      placeholder="Pricing 4"
                      value={quotePrice4}
                      onChange={(e) => {
                        setQuotePrice4(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field w-full my-2">
              <div className="control w-full  ">
                <div className="flex flex-wrap -mx-3 ">
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_pricing5"
                      onDoubleClick={() => {
                        setQuotePrice5("QTY.................@Rp");
                      }}
                    >
                      Pricing 5
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_pricing5"
                      type="text"
                      placeholder="Pricing 5"
                      value={quotePrice5}
                      onChange={(e) => {
                        setQuotePrice5(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-solid border-b-2 border-gray-300 p-5 ">
            <div className="field w-full ">
              <div className="control w-full  ">
                <div className="flex flex-wrap -mx-3 ">
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_mpn"
                    >
                      MPN (Optional)
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_mpn"
                      type="text"
                      placeholder="MPN"
                      value={quoteMpn}
                      onChange={(e) => {
                        setQuoteMpn(e.target.value);
                      }}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_currency"
                    >
                      Currency
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_currency"
                      type="text"
                      placeholder="Currency"
                      value={quoteCurrency}
                      onChange={(e) => {
                        setQuoteCurrency(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field w-full my-2">
              <div className="control w-full  ">
                <div className="flex flex-wrap -mx-3 ">
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_lt"
                    >
                      L/T (wks)
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_lt"
                      type="text"
                      placeholder="L/T"
                      value={quoteLt}
                      onChange={(e) => {
                        setQuoteLt(e.target.value);
                      }}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_cxl"
                    >
                      Cxl (wks)
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_cxl"
                      type="text"
                      placeholder="Cxl"
                      value={quoteCxl}
                      onChange={(e) => {
                        setQuoteCxl(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field w-full my-2">
              <div className="control w-full  ">
                <div className="flex flex-wrap -mx-3 ">
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_resch"
                    >
                      Resch (wks)
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_resch"
                      type="text"
                      placeholder="Resch"
                      value={quoteResch}
                      onChange={(e) => {
                        setQuoteResch(e.target.value);
                      }}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_delterm"
                    >
                      Delivery Terms
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_delterm"
                      type="text"
                      placeholder="Delivery Terms"
                      value={quoteDelTerm}
                      onChange={(e) => {
                        setQuoteDelTerm(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field w-full my-2">
              <div className="control w-full  ">
                <div className="flex flex-wrap -mx-3 ">
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_payterm"
                    >
                      Payment Terms
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_payterm"
                      type="text"
                      placeholder="Payment Terms"
                      value={quotePayTerm}
                      onChange={(e) => {
                        setQuotePayTerm(e.target.value);
                      }}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_efftype"
                    >
                      Effective Type
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_efftype"
                      type="text"
                      placeholder="Effective Type"
                      value={quoteEffType}
                      onChange={(e) => {
                        setQuoteEffType(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field w-full my-2">
              <div className="control w-full  ">
                <div className="flex flex-wrap -mx-3 ">
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_expdate"
                    >
                      Expiration Date
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_expdate"
                      type="text"
                      placeholder="Expiration Date"
                      value={quoteExpDate}
                      onChange={(e) => {
                        setQuoteExpDate(e.target.value);
                      }}
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
                      htmlFor="quote_origin"
                    >
                      Country of Origin
                    </label>
                    <input
                      className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="quote_origin"
                      type="text"
                      placeholder="Country of Origin"
                      value={quoteOrigin}
                      onChange={(e) => {
                        setQuoteOrigin(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-5">
            <Button fullSized type="submit">
              Insert
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default NewQuotation;
