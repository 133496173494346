import React, { useEffect, useState } from "react";
import axios from "axios";

function MaterialLocationSelect({ onChangeList, dataSet, locked }) {
  const [productLocation, setProductLocation] = useState([]);
  const [selectedProdLocation, setSelectedProdLocation] = useState(0);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://eps.elkomprintama.com/get/api/store/location`
      );
      if (
        response.data &&
        response.data.result &&
        response.data.result.length > 0
      ) {
        setProductLocation(response.data.result);

        if (dataSet && dataSet.product_location_id) {
          setSelectedProdLocation(dataSet.product_location_id);
          onChangeList(dataSet.product_location_id);
        }
      } else {
        setProductLocation([]);

        onChangeList(""); // You might adjust this based on your needs
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        alert(error.response.data.msg);
      } else {
        alert("An error occurred");
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run the effect only once

  const handleClientChange = (e) => {
    onChangeList(e.target.value);
    setSelectedProdLocation(e.target.value);
  };

  return (
    <div className="field w-full my-2 mb-5">
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor="mat_location"
      >
        Location
      </label>
      <div className="control w-full  ">
        <select
          className={`block appearance-none w-full border   ${
            locked
              ? "border-gray-200 text-gray-500"
              : "border-green-400 text-gray-700"
          } 
           py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
          id="mat_location"
          name="mat_location"
          onChange={handleClientChange}
          value={selectedProdLocation}
          disabled={locked}
        >
          <option value="0">Select Group</option>
          {Array.isArray(productLocation) ? (
            productLocation.map((data, index) => (
              <option
                value={data.product_location_id}
                key={`location-${index}`}
              >
                {data.product_location_name.toUpperCase()}
              </option>
            ))
          ) : (
            <option value="" key="empty-option">
              Loading...
            </option>
          )}
        </select>
      </div>
    </div>
  );
}

export default MaterialLocationSelect;
