import React, { useState, useEffect } from "react";
import { Button } from "flowbite-react";
import axios from "axios";

function UsersComponent() {
  const [userLevel, setUserLevel] = useState([]);
  const [selectedUserLevel, setSelectedUserLevel] = useState(0);
  const [userName, setUserName] = useState("");
  const [userPass, setUserPass] = useState("");
  const [confUserPass, setConfUserPass] = useState("");

  useEffect(() => {
    getUserLevel();
  }, []);

  const handleUserLevelChange = (e) => {
    setSelectedUserLevel(e.target.value);
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };

  const handleUserPassChange = (e) => {
    setUserPass(e.target.value);
  };

  const handleConfUserPassChange = (e) => {
    setConfUserPass(e.target.value);
  };

  const getUserLevel = async (e) => {
    try {
      const response = await axios.get(
        `https://eps.elkomprintama.com/get/api/users/levels`
      );
      if (response.status === 200) {
        // Access the 'msg' property from the response data
        setUserLevel(response.data);
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.msg);
      }
    }
  };

  const newUser = async (e) => {
    try {
      const response = await axios.post(
        `https://eps.elkomprintama.com/post/api/users`,
        {
          username: userName,
          password: userPass,
          confPassword: confUserPass,
          user_lvl: selectedUserLevel,
        }
      );
      if (response.status === 200) {
        // Access the 'msg' property from the response data
        alert(response.data.msg);
        setUserName("");
        setUserPass("");
        setConfUserPass("");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.msg);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userPass != confUserPass) {
      window.alert("Password not match!");
      return;
    }
    if (selectedUserLevel === 0) {
      window.alert("Please select level!");
      return;
    }
    const confirmation = window.confirm(
      "Are you sure you want to insert the data?"
    );

    if (confirmation) {
      newUser();
    }
  };

  return (
    <>
      <div className={`w-full  rounded-lg `}>
        <label
          className={`block uppercase text-white  mb-2 tracking-wide  text-2xl text-center  font-bold `}
        >
          New User
        </label>
      </div>
      <div className=" py-5  bg-gray-100 shadow-md px-5 rounded-lg">
        <form className="" onSubmit={handleSubmit}>
          <div className="field w-full my-2">
            <div className="flex flex-wrap -mx-3 mb-5">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="product_name"
                >
                  User Name
                </label>
                <input
                  className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id="username"
                  name="username"
                  type="text"
                  placeholder="Username"
                  value={userName}
                  onChange={handleUserNameChange}
                />
              </div>
            </div>
          </div>
          <div className="field w-full my-2">
            <div className="flex flex-wrap -mx-3 mb-5">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="product_name"
                >
                  Password
                </label>
                <input
                  className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id="userpass"
                  name="userpass"
                  type="password"
                  placeholder="Password"
                  value={userPass}
                  onChange={handleUserPassChange}
                />
              </div>
            </div>
          </div>
          <div className="field w-full my-2">
            <div className="flex flex-wrap -mx-3 mb-5">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="product_name"
                >
                  Confirm Password
                </label>
                <input
                  className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id="conf_userpass"
                  name="conf_userpass"
                  type="password"
                  placeholder="Confirm Password"
                  value={confUserPass}
                  onChange={handleConfUserPassChange}
                />
              </div>
            </div>
          </div>

          <div className="w-full mb-5 ">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="product_unit"
            >
              Level
            </label>
            <select
              className="block appearance-none w-full border border-green-400 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="product_unit"
              name="product_unit"
              onChange={handleUserLevelChange}
            >
              <option value={0}>Please Select</option>
              {Array.isArray(userLevel) ? (
                userLevel.map((data, index) => (
                  <option value={data.user_lvl} key={`level-${index}`}>
                    {data.level}
                  </option>
                ))
              ) : (
                <option value="" key="empty-option">
                  Loading... {/* or any other placeholder */}
                </option>
              )}
            </select>
          </div>
          <div className="w-full ">
            <Button fullSized type="submit">
              Insert
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default UsersComponent;
