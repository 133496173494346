import Layout from "./Layout";
import NewOrder from "../components/NewOrder";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getMe } from "../features/authSlice.js";

const AddOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError, user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);

  return (
    <>
      {user && (
        <Layout>
          <NewOrder></NewOrder>
        </Layout>
      )}
      {/* Add a loader or handle unauthorized access */}
    </>
  );
};

export default AddOrders;
