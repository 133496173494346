import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "flowbite-react";
import RadioButton from "./RadioButton";
import DateTimePicker from "./DateTimePicker";
import Operators from "./Operators";
import QcList from "./QcList";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

function UpdateModal({ isVisible, onClose, orderData, refreshData }) {
  const [radioSelected, setRadioSelected] = useState("");
  const [inputDateSelected, setInputDateSelected] = useState(new Date());
  const [deliveryDateSelected, setDeliveryDateSelected] = useState(
    new Date(new Date().setDate(new Date().getDate() + 7))
  );
  const [qtyOrder, setQtyOrder] = useState("");
  const [poOrder, setPoOrder] = useState("");
  const [oprSelected, setOprSelected] = useState("");
  const [qcSelected, setQcSelected] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Check if orderData has a valid value for radioSelected
    if (orderData) {
      setRadioSelected(orderData.status_id);
      setInputDateSelected(new Date(orderData.date));
      setDeliveryDateSelected(new Date(orderData.dlvr_date));
      setQtyOrder(orderData.qty);
      setPoOrder(orderData.po_num);
      setOprSelected(orderData.opr_id);
      setQcSelected(orderData.qc_id);
    }
  }, [orderData]);

  if (!isVisible) return null;

  const updateOrder = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`https://eps.elkomprintama.com/patch/api/orders/`, {
        dateOrder: format(inputDateSelected, "yyyy-MM-dd"),
        delivery: format(deliveryDateSelected, "yyyy-MM-dd"),
        operator: oprSelected,
        qc: qcSelected,
        status_id: radioSelected,
        order_id: orderData.order_id,
        qty: qtyOrder,
        po_number: poOrder,
      });
      alert("Data Updated");
      navigate("/orderlist");
      onClose();
      refreshData();
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.msg);
      }
    }
  };

  const handleRadioChange = (e) => {
    setRadioSelected(e.target.value);
  };

  const handleInputDateChange = (date) => {
    setInputDateSelected(date);
  };

  const handleDeliveryDateChange = (date) => {
    setDeliveryDateSelected(date);
  };

  const handleQtyChange = (e) => {
    setQtyOrder(e.target.value);
  };

  const handlePoChange = (e) => {
    const uppercaseValue = e.target.value.toUpperCase();
    setPoOrder(uppercaseValue);
  };

  const handleOprChange = (e) => {
    setOprSelected(e.target.value);
  };

  const handleQcChange = (e) => {
    setQcSelected(e.target.value);
  };

  // const handleClose = (e) => {
  //   if (e.target.id === "wrapper") onClose();
  // };

  const handleClose = (e) => {
    setRadioSelected(orderData.status_id);
    setInputDateSelected(new Date(orderData.date));
    setDeliveryDateSelected(new Date(orderData.dlvr_date));
    setQtyOrder(orderData.qty);
    setPoOrder(orderData.po_num);
    setOprSelected(orderData.opr_id);
    setQcSelected(orderData.qc_id);
    onClose();
  };

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex flex-col justify-center items-center"
        // id="wrapper"
        // onClick={handleClose}
      >
        <div className="w-full max-w-2xl text-end ">
          <button
            onClick={handleClose}
            className="text-red-700 pr-1 pb-1 font-bold text-xl "
          >
            X
          </button>
        </div>
        <div className=" bg-white w-full max-w-2xl  rounded-lg flex flex-col">
          <form onSubmit={updateOrder} className="w-full max-w-2xl p-10 ">
            <RadioButton
              radioData={radioSelected}
              onchangeDate={handleRadioChange}
            />
            <DateTimePicker
              firstDateValue={inputDateSelected}
              onChangeFirstDate={handleInputDateChange}
              secondDateValue={deliveryDateSelected}
              onChangeSecondDate={handleDeliveryDateChange}
              firstLabel={"Order Date"}
              secondLabel={"Delivery Date"}
            />
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full md:w-1/4 px-3 ">
                <label
                  className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                  htmlFor="grid-id"
                >
                  ID
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-id"
                  type="text"
                  placeholder="ID"
                  value={orderData.order_id}
                  readOnly
                  disabled
                />
              </div>
              <div className="w-full md:w-3/4 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                  htmlFor="grid-customer"
                >
                  Customer
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-customer"
                  type="text"
                  placeholder="Customer"
                  value={orderData.nme}
                  readOnly
                  disabled
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                  htmlFor="grid-jobsheet"
                >
                  Jobsheet
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-jobsheet"
                  type="text"
                  placeholder="Jobsheet"
                  value={orderData.job_name}
                  readOnly
                  disabled
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-5">
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                  htmlFor="grid-size"
                >
                  Size
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-size"
                  type="text"
                  placeholder="Size"
                  value={orderData.size}
                  readOnly
                  disabled
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                  htmlFor="grid-pn"
                >
                  P/N
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-pn"
                  type="text"
                  placeholder="P/N"
                  value={orderData.pn_cust}
                  readOnly
                  disabled
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-5">
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                  htmlFor="grid-orderqty"
                >
                  Order Qty
                </label>
                <input
                  className="appearance-none block w-full text-gray-700 border border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-orderqty"
                  type="text"
                  placeholder="Size"
                  value={qtyOrder}
                  onChange={handleQtyChange}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                  htmlFor="grid-ponum"
                >
                  PO Number
                </label>
                <input
                  className="appearance-none block w-full text-gray-700 border border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-ponum"
                  type="text"
                  placeholder="P/N"
                  value={poOrder}
                  onChange={handlePoChange}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-5">
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                  htmlFor="grid-operator"
                >
                  Operator
                </label>
                <Operators
                  onChangeOpr={handleOprChange}
                  oprData={oprSelected}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                  htmlFor="grid-qc"
                >
                  QC
                </label>
                <QcList onChangeQc={handleQcChange} qcData={qcSelected} />
              </div>
            </div>

            <div className="w-full ">
              <Button fullSized type="submit">
                Update
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UpdateModal;
