import StockInOut from "../components/StockInOut";
import Layout from "./Layout";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getMe } from "../features/authSlice.js";

function StockOutPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError, user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);

  return (
    <>
      {user && (
        <Layout>
          <StockInOut stockType={"Stock Out"}></StockInOut>
        </Layout>
      )}
      {/* Add a loader or handle unauthorized access */}
    </>
  );
}

export default StockOutPage;
