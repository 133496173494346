import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/authSlice";
import oprReducer from "../features/OperatorSlice";
import qcReducer from "../features/qcSlice";
import pageReducer from "../features/PageSlice";
import clientReducer from "../features/ClientSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    operator: oprReducer,
    qc: qcReducer,
    pages: pageReducer,
    clients: clientReducer,
  },
});
