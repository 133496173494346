import React, { useState, useEffect } from "react";
import { Button } from "flowbite-react";
import axios from "axios";
import { useSelector } from "react-redux";

function UpdateUser() {
  const [userPass, setUserPass] = useState("");
  const [confUserPass, setConfUserPass] = useState("");
  const { user } = useSelector((state) => state.auth);

  const handleUserPassChange = (e) => {
    setUserPass(e.target.value);
  };

  const handleConfUserPassChange = (e) => {
    setConfUserPass(e.target.value);
  };

  const newUser = async (userId) => {
    try {
      console.log(
        `https://eps.elkomprintama.com/patch/api/user/${user.iduser}`
      );
      const response = await axios.patch(
        `https://eps.elkomprintama.com/patch/api/user/${user.iduser}`,
        {
          password: userPass,
          confPassword: confUserPass,
        }
      );
      if (response.status === 200) {
        // Access the 'msg' property from the response data
        alert(response.data.msg);
        setUserPass("");
        setConfUserPass("");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.msg);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userPass != confUserPass) {
      window.alert("Password not match!");
      return;
    }

    const confirmation = window.confirm(
      "Are you sure you want to insert the data?"
    );

    if (confirmation) {
      newUser(user.iduser);
    }
  };

  return (
    <div className="relative h-full overflow-x-auto bg-gray-100 shadow-md px-5 rounded-lg">
      <label className="block uppercase my-2 tracking-wide text-gray-700 text-xl text-center underline font-bold ">
        Update Credential
      </label>
      <form className="my-5" onSubmit={handleSubmit}>
        <div className="field w-full my-2">
          <div className="flex flex-wrap -mx-3 mb-5">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="product_name"
              >
                Change Password
              </label>
              <input
                className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="userpass"
                name="userpass"
                type="password"
                placeholder="Password"
                value={userPass}
                onChange={handleUserPassChange}
              />
            </div>
          </div>
        </div>
        <div className="field w-full my-2">
          <div className="flex flex-wrap -mx-3 mb-5">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="product_name"
              >
                Confirm Password
              </label>
              <input
                className="appearance-none block w-full border bg-white border-green-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="conf_userpass"
                name="conf_userpass"
                type="password"
                placeholder="Confirm Password"
                value={confUserPass}
                onChange={handleConfUserPassChange}
              />
            </div>
          </div>
        </div>

        <div className="w-full ">
          <Button fullSized type="submit">
            Insert
          </Button>
        </div>
      </form>
    </div>
  );
}

export default UpdateUser;
