import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subQuote: false,
  subJobsheet: false,
  subOrders: false,
  subStore: false,
  subDocs: false,
  subDiecut: false,
};

export const pageSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    toggleQuote: (state) => {
      state.subQuote = !state.subQuote;
      state.subJobsheet = false;
      state.subOrders = false;
      state.subStore = false;
      state.subDocs = false;
      state.subDiecut = false;
    },
    toggleJobsheet: (state) => {
      state.subJobsheet = !state.subJobsheet;
      state.subQuote = false;
      state.subOrders = false;
      state.subStore = false;
      state.subDocs = false;
      state.subDiecut = false;
    },
    toggleOrders: (state) => {
      state.subOrders = !state.subOrders;
      state.subJobsheet = false;
      state.subQuote = false;
      state.subStore = false;
      state.subDocs = false;
      state.subDiecut = false;
    },
    toggleStore: (state) => {
      state.subStore = !state.subStore;
      state.subJobsheet = false;
      state.subOrders = false;
      state.subQuote = false;
      state.subDocs = false;
      state.subDiecut = false;
    },
    toggleDocs: (state) => {
      state.subDocs = !state.subDocs;
      state.subJobsheet = false;
      state.subOrders = false;
      state.subStore = false;
      state.subQuote = false;
      state.subDiecut = false;
    },
    toggleDiecut: (state) => {
      state.subDiecut = !state.subDiecut;
      state.subJobsheet = false;
      state.subOrders = false;
      state.subStore = false;
      state.subQuote = false;
      state.subDocs = false;
    },
  },
});

export const {
  toggleQuote,
  toggleJobsheet,
  toggleOrders,
  toggleStore,
  toggleDocs,
  toggleDiecut,
} = pageSlice.actions;

export default pageSlice.reducer;
