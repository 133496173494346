import React, { useEffect } from "react";
import Layout from "./Layout";
import NewMaterialLocation from "../components/NewMaterialLocation.jsx";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "../features/authSlice.js";

const AddMatLocation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError, user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);

  return (
    <>
      {user && (
        <Layout>
          <NewMaterialLocation></NewMaterialLocation>
        </Layout>
      )}
      {/* Add a loader or handle unauthorized access */}
    </>
  );
};

export default AddMatLocation;
