import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  clients: null,
  message: "",
  isLoaded: false,
};

export const getClients = createAsyncThunk(
  "user/getClients",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `https://eps.elkomprintama.com/get/api/clients`
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const clientSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClients.fulfilled, (state, action) => {
      state.clients = action.payload;
      state.isLoaded = true;
    });
    builder.addCase(getClients.rejected, (state, action) => {
      state.message = action.payload;
      state.isLoaded = false;
    });
  },
});

export default clientSlice.reducer;
