import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  qcs: null,
  message: "",
};

export const getQc = createAsyncThunk("user/getQc", async (_, thunkAPI) => {
  try {
    const response = await axios.get(
      `https://eps.elkomprintama.com/get/api/qc`
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      const message = error.response.data.msg;
      return thunkAPI.rejectWithValue(message);
    }
  }
});

export const qcSlice = createSlice({
  name: "qc",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getQc.fulfilled, (state, action) => {
      state.qcs = action.payload;
    });
    builder.addCase(getQc.rejected, (state, action) => {
      state.message = action.payload;
    });
  },
});

export default qcSlice.reducer;
