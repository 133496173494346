import React, { useEffect, useState } from "react";
import pdfFonts from "../pdf_resource/vfs_fonts.js";
import pdfMake from "pdfmake/build/pdfmake";
import { format } from "date-fns";

import { logoElkom, logoIso } from "../pdf_resource/encoded-logo.js";

import { fonts } from "../pdf_resource/pdfFont.js";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = fonts;

function PdfMake({ dataSet }) {
  const pageWidth = 595; // A4 page width in points
  const desiredWidthPercentage = 25; // Adjust as needed
  const desiredWidth = (pageWidth * desiredWidthPercentage) / 100;
  const docDefinition = {
    content: [
      {
        columns: [
          {
            image: `${logoElkom}`,
            width: desiredWidth,
          },
          {
            image: `${logoIso}`,
            width: desiredWidth,
            style: "anotherStyle",
          },
        ],
        columnGap: 210,
        margin: [0, 0, 0, 15],
      },
      {
        columns: [
          {
            text: `Our Ref`,
            width: "23%",
          },
          {
            text: `:`,
            width: "auto",
          },
          {
            text: `EQ / ELK / ${dataSet.quote_id}`,
          },
        ],
        columnGap: 10,
      },
      {
        columns: [
          {
            text: `Date`,
            width: "23%",
          },
          {
            text: `:`,
            width: "auto",
          },
          {
            text: `${format(new Date(dataSet.quote_date), "dd-MM-yyyy")}`,
          },
        ],
        columnGap: 10,
        margin: [0, 0, 0, 10],
      },
      { text: `${dataSet.nme}`, style: { bold: true } },
      {
        columns: [
          {
            text: `Attn`,
            width: "23%",
          },
          {
            text: `:`,
            width: "auto",
          },
          {
            text: `${dataSet.attn}`,
          },
        ],
        columnGap: 10,
        margin: [0, 0, 0, 10],
      },
      { text: "QUOTATION", style: { bold: true }, margin: [0, 0, 0, 10] },
      { text: "Dear Mr/Ms." },
      {
        text: "We are pleased to submit you our best price for your reference",
        margin: [0, 0, 0, 10],
      },
      {
        columns: [
          {
            text: `Description`,
            width: "23%",
          },
          {
            text: `:`,
            width: "auto",
          },
          {
            text: `${dataSet.descr}`,
          },
        ],
        columnGap: 10,
      },
      {
        columns: [
          {
            text: `Part Number`,
            width: "23%",
          },
          {
            text: `:`,
            width: "auto",
          },
          {
            text: `${dataSet.pn}`,
          },
        ],
        columnGap: 10,
      },

      {
        columns: [
          {
            text: `Size`,
            width: "23%",
          },
          {
            text: `:`,
            width: "auto",
          },
          {
            text: `${dataSet.size}`,
          },
        ],
        columnGap: 10,
      },
      {
        columns: [
          {
            text: `Material`,
            width: "23%",
          },
          {
            text: `:`,
            width: "auto",
          },
          {
            text: `${dataSet.mat}`,
          },
        ],
        columnGap: 10,
      },
      {
        columns: [
          {
            text: `Printing`,
            width: "23%",
          },
          {
            text: `:`,
            width: "auto",
          },
          {
            text: `${dataSet.clr}`,
          },
        ],
        columnGap: 10,
      },
      {
        columns: [
          {
            text: `Price`,
            width: "23%",
          },
          {
            text: `:`,
            width: "auto",
          },
          {
            text: `${dataSet.price1}`,
          },
        ],
        columnGap: 10,
        margin: [0, 0, 0, 0],
      },
      {
        columns: [
          {
            text: ` `,
            width: "23%",
          },
          {
            text: `${dataSet.price2 ? ":" : ""}`,
            width: "auto",
          },
          {
            text: `${dataSet.price2 ? dataSet.price2 : ""}`,
          },
        ],
        columnGap: 10,
      },
      {
        columns: [
          {
            text: ` `,
            width: "23%",
          },
          {
            text: `${dataSet.price3 ? ":" : ""}`,
            width: "auto",
          },
          {
            text: `${dataSet.price3 ? dataSet.price3 : ""}`,
          },
        ],
        columnGap: 10,
      },
      {
        columns: [
          {
            text: ` `,
            width: "23%",
          },
          {
            text: `${dataSet.price4 ? ":" : ""}`,
            width: "auto",
          },
          {
            text: `${dataSet.price4 ? dataSet.price4 : ""}`,
          },
        ],
        columnGap: 10,
      },
      {
        columns: [
          {
            text: ` `,
            width: "23%",
          },
          {
            text: `${dataSet.price5 ? ":" : ""}`,
            width: "auto",
          },
          {
            text: `${dataSet.price5 ? dataSet.price5 : ""}`,
          },
        ],
        columnGap: 10,
      },
      {
        columns: [
          {
            text: `MPN`,
            width: "23%",
            style: { fontSize: 12 },
          },
          {
            text: `:`,
            width: "auto",
            style: { fontSize: 12 },
          },
          {
            text: `${dataSet.mpn}`,
            style: { fontSize: 12 },
          },
        ],
        columnGap: 5,
        absolutePosition: { x: 40, y: 455 },
      },
      {
        columns: [
          {
            text: `Currency`,
            width: "23%",
            style: { fontSize: 12 },
          },
          {
            text: `:`,
            width: "auto",
            style: { fontSize: 12 },
          },
          {
            text: `${dataSet.curr}`,
            style: { fontSize: 12 },
          },
        ],
        columnGap: 5,
        absolutePosition: { x: 40, y: 470 },
      },
      {
        columns: [
          {
            text: `L/T (wks)`,
            width: "23%",
            style: { fontSize: 12 },
          },
          {
            text: `:`,
            width: "auto",
            style: { fontSize: 12 },
          },
          {
            text: `${dataSet.lead}`,
            style: { fontSize: 12 },
          },
        ],
        columnGap: 5,
        absolutePosition: { x: 40, y: 485 },
      },

      {
        columns: [
          {
            text: `Cxl (wks)`,
            width: "23%",
            style: { fontSize: 12 },
          },
          {
            text: `:`,
            width: "auto",
            style: { fontSize: 12 },
          },
          {
            text: `NCNR`,
            style: { fontSize: 12 },
          },
        ],
        columnGap: 5,
        absolutePosition: { x: 40, y: 500 },
      },
      {
        columns: [
          {
            text: `Resch (wks)`,
            width: "23%",
            style: { fontSize: 12 },
          },
          {
            text: `:`,
            width: "auto",
            style: { fontSize: 12 },
          },
          {
            text: `NCNR`,
            style: { fontSize: 12 },
          },
        ],
        columnGap: 5,
        absolutePosition: { x: 40, y: 515 },
      },
      {
        columns: [
          {
            text: `Part Type (S/C/SC)`,
            width: "23%",
            style: { fontSize: 12 },
          },
          {
            text: `:`,
            width: "auto",
            style: { fontSize: 12 },
          },
          {
            text: `C`,
            style: { fontSize: 12 },
          },
        ],
        columnGap: 5,
        absolutePosition: { x: 40, y: 530 },
      },
      {
        columns: [
          {
            text: `Country of Origin`,
            width: "23%",
            style: { fontSize: 12 },
          },
          {
            text: `:`,
            width: "auto",
            style: { fontSize: 12 },
          },
          {
            text: `${dataSet.coo}`,
            style: { fontSize: 12 },
          },
        ],
        columnGap: 5,
        absolutePosition: { x: 40, y: 545 },
      },
      {
        columns: [
          {
            text: `Delivery Terms`,
            width: "23%",
            style: { fontSize: 12 },
          },
          {
            text: `:`,
            width: "auto",
            style: { fontSize: 12 },
          },
          {
            text: `FOB BATAM`,
            style: { fontSize: 12 },
          },
        ],
        columnGap: 5,
        absolutePosition: { x: 40, y: 560 },
      },
      {
        columns: [
          {
            text: `Payment Terms`,
            width: "23%",
            style: { fontSize: 12 },
          },
          {
            text: `:`,
            width: "auto",
            style: { fontSize: 12 },
          },
          {
            text: `${dataSet.term}`,
            style: { fontSize: 12 },
          },
        ],
        columnGap: 5,

        absolutePosition: { x: 40, y: 575 },
      },
      {
        columns: [
          {
            text: `Effective Type`,
            width: "23%",
            style: { fontSize: 12 },
          },
          {
            text: `:`,
            width: "auto",
            style: { fontSize: 12 },
          },
          {
            text: `${dataSet.effect_type}`,
            style: { fontSize: 12 },
          },
        ],
        columnGap: 5,

        absolutePosition: { x: 40, y: 590 },
      },
      {
        columns: [
          {
            text: `MPN Lead Free (Y/N)`,
            width: "23%",
            style: { fontSize: 12 },
          },
          {
            text: `:`,
            width: "auto",
            style: { fontSize: 12 },
          },
          {
            text: `Yes`,
            style: { fontSize: 12 },
          },
        ],
        columnGap: 5,
        absolutePosition: { x: 40, y: 605 },
      },
      {
        columns: [
          {
            text: `MPN ROHS (Y/N)`,
            width: "23%",
            style: { fontSize: 12 },
          },
          {
            text: `:`,
            width: "auto",
            style: { fontSize: 12 },
          },
          {
            text: `Yes`,
            style: { fontSize: 12 },
          },
        ],
        columnGap: 5,
        absolutePosition: { x: 40, y: 620 },
      },
      {
        columns: [
          {
            text: `Expiration Date`,
            width: "23%",
            style: { fontSize: 12 },
          },
          {
            text: `:`,
            width: "auto",
            style: { fontSize: 12 },
          },
          {
            text: `3 months after the date of quotation`,
            style: { fontSize: 12 },
          },
        ],
        columnGap: 5,
        absolutePosition: { x: 40, y: 635 },
      },
      {
        text: "Please do not hesitate to contact us. Should you require any clarification. ",
        absolutePosition: { x: 40, y: 655 },
      },
      {
        text: "We are looking forward in receiving an order from you soon.",
        absolutePosition: { x: 40, y: 670 },
      },
      {
        text: "Your faithfully.",
        absolutePosition: { x: 40, y: 685 },
      },
      {
        text: `${dataSet.admin}`,
        margin: [0, 0, 0, 10],
        absolutePosition: { x: 40, y: 700 },
      },

      {
        canvas: [
          {
            type: "line",
            x1: 0,
            y1: 5, // Y-coordinate of the starting point
            x2: 520, // X-coordinate of the ending point
            y2: 5, // Y-coordinate of the ending point
            lineWidth: 2, // Line width in points
          },
        ],
        absolutePosition: { x: 40, y: 720 },
      },
      {
        text: "PT ELKOM PRINTAMA BATAM.",
        alignment: "center",
        style: { fontSize: 9 },
        absolutePosition: { x: 40, y: 735 },
      },
      {
        text: "Jl. Bunga Raya Plaza 91 Square Block H Lt. III Batam -  Indonesia",
        alignment: "center",
        style: { fontSize: 9 },
        absolutePosition: { x: 40, y: 745 },
      },
      {
        text: "Tel : (0778) 429548, 432489, 421391 Fax : (0778) 429547",
        alignment: "center",
        style: { fontSize: 9 },
        absolutePosition: { x: 40, y: 755 },
      },
      {
        text: "www.elkomprintama.com",
        alignment: "center",
        style: { fontSize: 9 },
        absolutePosition: { x: 40, y: 765 },
      },
      {
        text: "F-QP-MKT-01-01 REV 01",
        alignment: "right",
        style: { fontSize: 9 },
        absolutePosition: { x: 40, y: 780 },
      },
      {
        text: "This Document is Computer Generated, No Sign is Required",
        alignment: "right",
        style: { fontSize: 9 },
        absolutePosition: { x: 40, y: 790 },
      },
    ],

    defaultStyle: {
      fontSize: 15,
      font: "Times",
    },
  };

  const [iframeSrc, setIframeSrc] = useState(null);
  const [url, setUrl] = useState(null);

  const previewPdf = () => {
    const pdfGenerator = pdfMake.createPdf(docDefinition);

    pdfGenerator.getBlob((blob) => {
      const url = URL.createObjectURL(blob);

      // Create an anchor element
      const link = document.createElement("a");
      link.href = url;

      // Set the download attribute with the desired filename
      link.download = `Quotation-${dataSet.quote_id}-${dataSet.descr}-${dataSet.size}.pdf`;

      // Append the anchor element to the document body
      document.body.appendChild(link);

      // Simulate a click to trigger the download
      link.click();

      // Remove the anchor element
      document.body.removeChild(link);
    });
  };

  useEffect(() => {
    // Preview PDF when the component mounts
  }, []); // Run this effect only once

  return (
    <div className="App">
      <button
        className=" text-green-500 dark:text-red-500 hover:underline px-3"
        onClick={previewPdf}
      >
        Download
      </button>
      {iframeSrc && (
        <iframe
          title="Preview PDF"
          src={iframeSrc}
          width="100%"
          height="800px"
          style={{ border: "none" }}
        ></iframe>
      )}
    </div>
  );
}

export default PdfMake;
