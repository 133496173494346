import React, { useEffect, useState } from "react";
import axios from "axios";

function MaterialListSelect({ onChangeList, location_id, dataSet, locked }) {
  const [materialList, setMaterialList] = useState([]);
  const [defaultSelected, setDefaultSelected] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [originalMaterialList, setOriginalMaterialList] = useState([]);

  const fetchData = async (location_id) => {
    try {
      const response = await axios.get(
        `https://eps.elkomprintama.com/get/api/store/product_name/${location_id}`
      );
      if (
        response.data &&
        response.data.result &&
        response.data.result.length > 0
      ) {
        const data = response.data.result;
        setMaterialList(data);
        setOriginalMaterialList(data);
        setDefaultSelected([data[0].product_id, data[0].product_name]);
        onChangeList([data[0].product_id, data[0].product_name]);
        if (dataSet && dataSet.product_code) {
          setSearchValue(dataSet.product_code);
          const filteredList = data.filter((data) =>
            data.product_code
              .toLowerCase()
              .includes(dataSet.product_code.toLowerCase())
          );
          setMaterialList(filteredList);
          const defaultId = filteredList[0]?.product_id || "";
          const defaultName = filteredList[0]?.product_name || "";
          setDefaultSelected([defaultId, defaultName]);
          onChangeList([defaultId, defaultName]);
        }
      } else {
        setMaterialList([]);
        setOriginalMaterialList([]);
        setDefaultSelected([]);
        onChangeList([]);
      }
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    if (!location_id) {
      return;
    }

    fetchData(location_id);
  }, [location_id]);

  const handleClientChange = (e) => {
    setDefaultSelected([
      e.target.value,
      e.target.options[e.target.selectedIndex].textContent,
    ]);

    onChangeList([
      e.target.value,
      e.target.options[e.target.selectedIndex].textContent,
    ]);
  };

  const handleSearchChange = (e) => {
    const uppercaseValue = e.target.value.toUpperCase();
    setSearchValue(uppercaseValue);
    if (e.target.value.trim() === "") {
      setMaterialList(originalMaterialList);
      const defaultId = originalMaterialList[0]?.product_id || "";
      const defaultName = originalMaterialList[0]?.product_name || "";
      setDefaultSelected([defaultId, defaultName]); // Set default if there's a list
      onChangeList([defaultId, defaultName]); // Call onChangeList with the default value if there's a list
    } else {
      const filteredList = originalMaterialList.filter((data) =>
        data.product_code.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setMaterialList(filteredList);
      const defaultId = filteredList[0]?.product_id || "";
      const defaultName = filteredList[0]?.product_name || "";
      setDefaultSelected([defaultId, defaultName]); // Set default if there's a list
      onChangeList([defaultId, defaultName]); // Call onChangeList with the default value if there's a list
    }
  };

  return (
    <>
      <div className="field w-full my-2">
        <div className="control w-full  ">
          <div className="flex flex-wrap -mx-3 mb-5">
            <div className="w-full md:w-1/5 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="product_code"
              >
                Product Code
              </label>
              <input
                className={`appearance-none block w-full  border bg-white 
                ${
                  locked
                    ? "border-gray-200 text-gray-500"
                    : "border-green-400 text-gray-700"
                }
                
               rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`}
                id="product_code"
                name="product_code"
                placeholder={"Search Material"}
                value={searchValue}
                onChange={handleSearchChange}
                disabled={locked}
              />
            </div>
            <div className="w-full md:w-4/5 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="product_name"
              >
                Product Name
              </label>
              <select
                className={`block appearance-none w-full border 
                ${
                  locked
                    ? "border-gray-200 text-gray-500"
                    : "border-green-400 text-gray-700"
                } 
                py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                id="product_name"
                name="product_name"
                value={defaultSelected[0]}
                onChange={handleClientChange}
                disabled={locked}
              >
                {Array.isArray(materialList) ? (
                  materialList.map((data, index) => (
                    <option value={data.product_id} key={`matlist-${index}`}>
                      {`${data.product_code} - ${data.product_name}`}
                    </option>
                  ))
                ) : (
                  <option value="" key="empty-option">
                    Loading...
                  </option>
                )}
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default MaterialListSelect;
