import Layout from "./Layout";
import NewMaterial from "../components/NewMaterial";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getMe } from "../features/authSlice.js";

const AddMaterial = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError, user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, user, navigate]);

  return (
    <>
      {user && (
        <Layout>
          <NewMaterial />
        </Layout>
      )}
      {/* Add a loader or handle unauthorized access */}
    </>
  );
};

export default AddMaterial;
