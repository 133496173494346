import React, { useState, useEffect } from "react";
import axios from "axios";
import UpdateModal from "./UpdateModal";
import FinishGoodModal from "./FinishGoodModal";
import { TbTrashX, TbChecks } from "react-icons/tb";
const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showFGModal, setShowFGModal] = useState(false);
  const [orderData, setOrderData] = useState();

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    try {
      const response = await axios.get(
        `https://eps.elkomprintama.com/get/api/orders`
      );
      setOrders(response.data.result);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const deleteOrder = async (productId) => {
    await axios.delete(
      `https://eps.elkomprintama.com/delete/api/orders/${productId}`
    );
    getOrders();
  };

  function getStatusColor(jobStatus) {
    switch (jobStatus) {
      case "JOBSHEET":
        return "text-blue-500"; // Change to the desired color class
      case "ON PROCESS":
        return "text-green-500"; // Change to the desired color class
      case "QC CHECK":
        return "text-yellow-500 "; // Change to the desired color class
      case "FINISHED GOOD":
        return "text-gray-500"; // Change to the desired color class
      case "REWORK":
        return "text-red-500"; // Change to the desired color class
      default:
        return "text-gray-500"; // Default color class
    }
  }

  function getStatusColor_bg(jobStatus) {
    switch (jobStatus) {
      case "JOBSHEET":
        return "bg-blue-500 text-white"; // Change to the desired color class
      case "ON PROCESS":
        return "bg-green-500 text-white"; // Change to the desired color class
      case "QC CHECK":
        return "bg-yellow-500 text-white"; // Change to the desired color class
      case "FINISHED GOOD":
        return "bg-gray-500 text-white"; // Change to the desired color class
      case "REWORK":
        return "bg-red-500 text-white"; // Change to the desired color class
      default:
        return "text-gray-500"; // Default color class
    }
  }

  function applyStripedStyle(index) {
    return index % 2 === 0 ? "border-b bg-gray-100" : " bg-white"; // Apply the striped-row class to even-indexed rows
  }

  return (
    <>
      <div className="relative h-5">
        <table className="text-sm w-full text-left text-gray-500 dark:text-gray-400 border-collapse">
          <thead className="sticky -top-8 text-x  s text-center text-white uppercase bg-gray-600 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="whitespace-nowrap px-6 py-3 border-r">
                Status
              </th>

              <th scope="col" className="whitespace-nowrap px-2 border-r">
                Name
              </th>
              <th scope="col" className="whitespace-nowrap px-2 border-r">
                Job Name
              </th>
              <th scope="col" className="whitespace-nowrap px-2 border-r">
                Size
              </th>
              <th scope="col" className="whitespace-nowrap px-2 border-r">
                Qty
              </th>
              <th scope="col" className="whitespace-nowrap px-2 border-r">
                PO Number
              </th>
              <th scope="col" className="whitespace-nowrap px-2 border-r">
                Delivery Date
              </th>
              <th scope="col" className="whitespace-nowrap px-2 border-r">
                Operator
              </th>
              <th scope="col" className="whitespace-nowrap px-2 border-r">
                QC
              </th>
              <th scope="col" className="whitespace-nowrap px-2 border-r"></th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr
                className={`border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-blue-50 dark:hover:bg-gray-600 ${applyStripedStyle(
                  index
                )} ${getStatusColor(order.job_status)}`}
                key={index}
                onDoubleClick={() => {
                  setOrderData(order);
                  setShowModal(!showModal);
                }}
              >
                <td
                  className={`px-6 font-bold whitespace-nowrap text-xs ${getStatusColor_bg(
                    order.job_status
                  )} border-r`}
                >
                  {order.job_status}
                </td>

                <td className="whitespace-nowrap px-2 border-r">{order.nme}</td>
                <td className="whitespace-nowrap px-2 border-r">
                  {order.job_name}
                </td>
                <td className="whitespace-nowrap px-2 border-r">
                  {order.size}
                </td>
                <td className="whitespace-nowrap px-2 border-r">{order.qty}</td>
                <td className="whitespace-nowrap px-2 border-r">
                  {order.po_num}
                </td>
                <td className="whitespace-nowrap px-2 border-r">
                  {new Date(order.dlvr_date).toLocaleDateString()}
                </td>
                <td className="whitespace-nowrap px-2 border-r">
                  {order.opr_name}
                </td>
                <td className="whitespace-nowrap px-2 border-r">
                  {order.qc_name}
                </td>
                <td className="whitespace-nowrap px-2 border-r py-3">
                  <button
                    className="font-medium text-red-500 dark:text-red-500 hover:underline px-3"
                    onClick={(e) => {
                      e.preventDefault();
                      setOrderData(order);
                      if (
                        window.confirm(
                          `Are You Sure You Want to Delete Order ID:${order.order_id}?`
                        ) === true
                      ) {
                        deleteOrder(order.order_id);
                        alert("Success");
                      } else {
                      }
                    }}
                  >
                    <TbTrashX size={"1.5em"} />
                  </button>

                  <button
                    className="font-medium text-green-500 dark:text-green-500 hover:underline"
                    onClick={() => {
                      setOrderData(order);
                      setShowFGModal(!showFGModal);
                    }}
                  >
                    <TbChecks size={"1.5em"} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <UpdateModal
        isVisible={showModal}
        orderData={orderData}
        onClose={() => {
          setShowModal(false);
        }}
        refreshData={() => {
          getOrders();
        }}
      />
      <FinishGoodModal
        isVisible={showFGModal}
        orderData={orderData}
        onClose={() => {
          setShowFGModal(false);
        }}
      />
    </>
  );
};

export default OrderList;
